import React, { useEffect, useState, useRef, useContext } from "react";
import { withNamespaces } from "react-i18next";

const Documentation = ({ t }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid page doc-page header-space">
      <div className="">
        <div className="title">
          <h3>{t("imprint")}</h3>
        </div>
        <div className="content">
          <p>
            myCinema.world <br />a brand of Imaculix AG
          </p>
          <p>
            Buckhauserstrasse 24 <br />
            8048 Zürich
            <br />
            +41 44 520 3200
            <br />
            <a href="mailto:contact@imaculix.ch">contact@imaculix.ch</a>
          </p>
          <p>
            {t("imprint-msg.p1")}
            <br />
            {t("imprint-msg.p2")}
            <br />
            Andy W. Bohli
          </p>
          <p>
            {t("imprint-msg.p3")}
            <br />
            Raphael Gmür
            <br />
            Sarah Bernhard
          </p>
          <p>
            {t("imprint-msg.p4")}
            <br />
            {t("imprint-msg.p5")}
          </p>
          <p>
            {t("imprint-msg.p6")}
            <br />
            Aktiengesellschaft (AG)
            <br />
            Treuhand: MTN Treuhand AG, 8700 Küsnacht
          </p>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(Documentation);
