import React, { useEffect, useState, useRef, useContext } from "react";
import { Modal } from "react-bootstrap";
import CountryCodeDropdown from "../CountryCodeDropdown";

import back from "@assets/pictures/svgs/back-modal.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  login,
  register,
  loginViaOtp,
  numberRegex,
  forgotPassword,
  startTimer,
  resetTimer,
} from "../../../store/auth/authActions";
import LoginOtpPopup from "./LoginOtpPopup.jsx";
import OtpPopup from "./OtpPopup.jsx";
import { withNamespaces } from "react-i18next";
import showPasswordIcon from "@assets/pictures/svgs/show-password.svg";
import hidePasswordIcon from "@assets/pictures/svgs/hide-password.svg";
import useIsMobile from "../../../customHooks/useIsMobile";

const LoginPopup = (props) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(``);
  const [firstName, setFirstName] = useState(``);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [phoneNoError, setPhoneNoError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastName, setLastName] = useState(``);
  const [password, setPassword] = useState(``);
  const [phone_no, setPhone_no] = useState(``);
  const [countryId, setCountryId] = useState(null);
  const [countryMobCode, setCountryMobCode] = useState(null);
  const [registerNew, setRegisterNew] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);
  const [loginField, setLoginField] = useState(``);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [submittedviaOtp, setSubmittedviaOtp] = useState(false);
  const [loginOtpPopup, setLoginOtpPopup] = useState(false);
  const t = props.t;
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [type, setType] = useState(null);
  const [loadingResendOtp, setLoadingResendOtp] = useState(false);
  const state = useSelector((state) => state);
  const {
    auth: {
      loginOtpError,
      timer: { minutes, seconds },
      timerCompleted,
    },
  } = state;
  const [allowResendLogin, setAllowResendLogin] = useState(
    minutes * 60 + seconds > 0 ? false : true
  );
  const [allowResend, setAllowResend] = useState(false);
  const [field, setField] = useState(``);

  const onErrorHandling = () => {
    if (loginError === "Password is incorrect") {
      setOtpPopup(true);
      props.closeFunc(false);
    } else {
      setRegisterNew(true);
      setSubmitted(false);
    }
  };
  const onClickResendLogin = () => {
    if (timerCompleted) {
      // setAllowResendLogin(false);
      setLoadingResendOtp(true);
      dispatch(
        loginViaOtp({
          loginField,
          callback: setLoadingResendOtp,
          successCallback: () => {
            dispatch(resetTimer());
            dispatch(startTimer());
            // setAllowResendLogin(false);
            // setInterval(() => {
            //   setAllowResendLogin(true);
            // }, (minutes * 60 + seconds) * 1000);
          },
        })
      );
    }
  };

  const onClickResend = () => {
    if (timerCompleted) {
      // setAllowResend(false);
      setLoadingResendOtp(true);
      dispatch(
        forgotPassword({
          field,
          callback: setLoadingResendOtp,
          successCallback: () => {
            // setAllowResend(false);
            dispatch(resetTimer());
            dispatch(startTimer());
            // setInterval(() => {
            //   setAllowResend(true);
            // }, (minutes * 60 + seconds) * 1000);
          },
        })
      );
    }
  };
  const isLoginWithOTPClicked = useRef(false);

  const onClickLoginViaOTP = () => {
    setType("otp");
    setSubmittedviaOtp(true);
    let count = 0;

    if (isLoginWithOTPClicked.current === true) {
      return;
    }

    if (!registerNew && loginField === ``) {
      count++;
    }

    if (count > 0) {
      return;
    }
    isLoginWithOTPClicked.current = true;
    setLoadingOtp(true);
    // setSubmittedviaOtp(false);
    setTimeout(() => {
      isLoginWithOTPClicked.current = false;
    }, 5 * 1000);
    dispatch(
      loginViaOtp({
        loginField,
        callback: setLoadingOtp,
        successCallback: () => {
          onCloseModal();
          setLoginOtpPopup(true);
          dispatch(resetTimer());
          dispatch(startTimer());
          // setAllowResendLogin(false);
          // setTimeout(
          //   () => setAllowResendLogin(true),
          //   (minutes * 60 + seconds) * 1000
          // );
        },
      })
    ).then((response) => {
      if (response.status === false) {
        setSubmittedviaOtp(false);
      }
    });
  };
  useEffect(() => {
    // setRegisterNew(registerNew);
  }, []);
  const onFormSubmit = (e) => {
    e.preventDefault();
    setType("email");
    setSubmitted(true);
    let count = 0;
    // if (registerNew && email !== "") {
    //   setEmailError(false);
    // }
    // if (registerNew && email === "") {
    //   setEmailError(true);
    //   count++;
    // }
    if (registerNew && firstName !== "") {
      setFirstNameError(false);
    }
    if (registerNew && firstName === "") {
      setFirstNameError(true);
      count++;
    }
    if (registerNew && lastName !== "") {
      setLastNameError(false);
    }
    if (registerNew && lastName === "") {
      setLastNameError(true);
      count++;
    }
    if (registerNew && phone_no !== "") {
      setPhoneNoError(false);
    }
    if (registerNew && (phone_no === "" || !countryId)) {
      setPhoneNoError(true);
      count++;
    }
    if (!registerNew && loginField === ``) {
      count++;
    }
    if (password !== "") {
      setPasswordError(false);
    }
    if (password === "") {
      setPasswordError(true);
      count++;
    }
    if (count > 0) {
      return;
    }
    setLoading(true);
    setSubmitted(false);
    if (registerNew) {
      dispatch(
        register({
          email,
          first_name: firstName,
          last_name: lastName,
          password,
          phone_no,
          country_id: countryId,
          callback: setLoading,
        })
      );
    } else {
      dispatch(login({ loginField, password, callback: setLoading }));
    }
  };
  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, registerError } = loginDetails;
  useEffect(() => {
    if (loggedInUser) {
      props.closeFunc(false);
    }
  }, [loggedInUser]);
  const [isNumber, setisNumber] = useState(false);

  useEffect(() => setisNumber(numberRegex(loginField)), [loginField]);
  const onCloseModal = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setPassword("");
    setPhone_no("");
    setCountryId(null);
    setCountryMobCode(null);
    props.closeFunc(false);
    // if (registerNew) {
    //   registerNew(true);
    // }
  };

  return (
    <>
      <LoginOtpPopup
        allowResendLogin={allowResendLogin}
        setAllowResendLogin={setAllowResendLogin}
        show={loginOtpPopup}
        closeFunc={() => {
          setLoginOtpPopup(false);
          props.closeFunc(true);
        }}
        onClickResendLogin={onClickResendLogin}
        loadingResendOtp={loadingResendOtp}
        loginModalCloseFunc={onCloseModal}
      />
      <OtpPopup
        onClickResend={onClickResend}
        allowResend={allowResend}
        setAllowResend={setAllowResend}
        show={otpPopup}
        closeFunc={() => {
          setOtpPopup(false);
          props.closeFunc(true);
        }}
        field={field}
        setField={setField}
        loadingResendOtp={loadingResendOtp}
        loginModalCloseFunc={onCloseModal}
      />
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="login-popup"
        centered
        onHide={() => {
          !isMobile && onCloseModal();
        }}
      >
        <section className="wrapper container-fluid">
          {!isMobile && (
            <img
              // src={props.register ? back : close}
              src={back}
              alt=""
              className="back-btn-main"
              onClick={onCloseModal}
            />
          )}
          <div className="d-flex register_tabs">
            <div
              className={`w-100 tabs py-2 ${!registerNew && "active"}`}
              onClick={() => setRegisterNew(false)}
            >
              {t(`header-footer-menu.Sign in`)}
            </div>
            <div
              className={`w-100 tabs py-2 ${registerNew && "active"}`}
              onClick={() => setRegisterNew(true)}
            >
              {/* {t(`login_form.Login`)} */}
              {t(`header-footer-menu.signup`)}
            </div>
          </div>

          <p className="title">
            {/* {registerNew ? t("Register") : t("Login")}{" "}
            {t("login_form.placeholders.email-mobile")} */}
          </p>

          <form className="user-form" onSubmit={onFormSubmit}>
            {!registerNew && (
              <>
                <label>
                  {t("login_form.placeholders.email-mobile")}
                  <div className="d-flex">
                    {isNumber && (
                      <CountryCodeDropdown
                        className="country_code_drop mr-2"
                        onSelectCountry={({ country_id, country_mob_code }) => {
                          setCountryId(country_id);
                          setCountryMobCode(country_mob_code);
                        }}
                      />
                    )}
                    <input
                      type="text"
                      placeholder={t(`Enter_email_or_mobile`)}
                      value={loginField}
                      onChange={(e) => setLoginField(e.target.value)}
                    />
                  </div>
                </label>

                <p className="error">
                  {(submitted || submittedviaOtp) &&
                    loginField === "" &&
                    t("field_required")}
                </p>
              </>
            )}
            {registerNew && (
              <>
                <label>
                  {t("login_form.Phone Number")}{" "}
                  <div className="d-flex">
                    <CountryCodeDropdown
                      className="country_code_drop mr-2"
                      onSelectCountry={({ country_id, country_mob_code }) => {
                        setCountryId(country_id);
                        setCountryMobCode(country_mob_code);
                      }}
                    />
                    <input
                      type="number"
                      placeholder={t(
                        "login_form.placeholders.Enter your phone number"
                      )}
                      value={phone_no}
                      min="10"
                      onChange={(e) => setPhone_no(e.target.value)}
                    />
                  </div>
                </label>
                {/* {submitted && phone_no === "" && ( */}
                <p className="error">
                  {submitted &&
                    phone_no === "" &&
                    t("login_form.error_messages.Phone number is required")}
                </p>
                {/* )} */}
              </>
            )}
            {registerNew && (
              <>
                <label>
                  {t("login_form.Email")}{" "}
                  <input
                    type="email"
                    placeholder={t("login_form.placeholders.Enter your email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                {/* {submitted && email === "" && ( */}
                {/* <p className="error">
                  {submitted &&
                    email === "" &&
                    t("login_form.error_messages.Email is required")}
                </p> */}
              </>
            )}
            {/* )} */}
            <div className="d-flex">
              <div className="pr-2">
                {registerNew && (
                  <>
                    <label>
                      {t("login_form.First Name")}{" "}
                      <input
                        type="text"
                        placeholder={t(
                          "login_form.placeholders.Enter your first name"
                        )}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </label>
                    {/* {submitted && firstName === "" && ( */}
                    <p className="error">
                      {submitted &&
                        firstName === "" &&
                        t("login_form.error_messages.First name is required")}
                    </p>
                    {/* )} */}
                  </>
                )}
              </div>

              <div className="pl-2">
                {registerNew && (
                  <>
                    <label>
                      {t("login_form.Last Name")}{" "}
                      <input
                        type="text"
                        placeholder={t(
                          "login_form.placeholders.Enter your last name"
                        )}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </label>
                    {/* {submitted && lastName === "" && ( */}
                    <p className="error">
                      {submitted &&
                        lastName === "" &&
                        t("login_form.error_messages.Last name is required")}
                    </p>
                    {/* )} */}
                  </>
                )}
              </div>
            </div>
            <label className="position-relative">
              {t("login_form.Password")}{" "}
              <input
                type={showLoginPassword ? "text" : "password"}
                autocomplete="new-password"
                placeholder={t("login_form.placeholders.Enter your password")}
                value={password}
                min="4"
                onChange={(e) => setPassword(e.target.value)}
              />
              {!showLoginPassword && (
                <img
                  alt=""
                  width="30"
                  height="30"
                  className="show-password-icon"
                  src={showPasswordIcon}
                  onClick={() => setShowLoginPassword((x) => !x)}
                />
              )}
              {showLoginPassword && (
                <img
                  alt=""
                  width="30"
                  height="30"
                  className="show-password-icon"
                  src={hidePasswordIcon}
                  onClick={() => setShowLoginPassword((x) => !x)}
                />
              )}
            </label>
            {/* {submitted && password === "" && ( */}
            {/* {submitted && password === "" && !submittedviaOtp && ( */}
            <p className="error">
              {submitted &&
                password === "" &&
                type === "email" &&
                t("login_form.error_messages.Password is required")}
            </p>

            {loginError &&
            !registerNew &&
            type === "email" &&
            password !== "" ? (
              <p className="error">
                {loginError},{" "}
                <span style={{ cursor: `pointer` }} onClick={onErrorHandling}>
                  {loginError === "Password is incorrect" ? (
                    <u>{t(`please click here to change password`)}</u>
                  ) : (
                    <u>{t(`please click here to sign up`)}</u>
                  )}
                </span>
              </p>
            ) : null}

            {registerError && registerNew ? (
              <p className="error">{registerError}</p>
            ) : null}

            {loginOtpError && type === "otp" && (
              <p className="error">
                {loginOtpError},{" "}
                <span
                  style={{ cursor: `pointer` }}
                  onClick={() => {
                    setRegisterNew(true);
                    setSubmitted(false);
                  }}
                >
                  <u>{t(`please click here to sign up`)}</u>
                </span>
              </p>
            )}

            {registerNew ? (
              <button
                type="submit"
                className={`btn-main mt-3 ${
                  registerNew && loading && "loader-text"
                }`}
              >
                {t("login_form.Confirm")}
              </button>
            ) : (
              <button
                type="submit"
                className={`btn-main mt-3 ${loading && "loader-text"}`}
              >
                {t("login_form.Login")}
              </button>
            )}

            <div className="d-flex justify-content-between align-items-center mt-3">
              {!registerNew && (
                <button
                  type="button"
                  className="btn-basic"
                  onClick={() => {
                    setOtpPopup(true);
                    props.closeFunc(false);
                  }}
                >
                  {t(`login_form.forgot-password`)}
                </button>
              )}

              {/* {!registerNew && (
              <button
                onClick={() => {
                  setOtpPopup(true);
                  props.closeFunc(false);
                }}
                className={`btn-main mt-3`}
              >
                {t(`login_form.forgot-password`)}
              </button>
            )} */}
              {!registerNew && (
                <button
                  type="button"
                  onClick={onClickLoginViaOTP}
                  className={`btn-basic ${loadingOtp ? "loader-text" : ""}`}
                >
                  {t(`Login via OTP`)}
                </button>
              )}
            </div>
          </form>
        </section>
      </Modal>
    </>
  );
};

export default withNamespaces()(LoginPopup);
