import React, { useState, useEffect, useContext } from "react";
import useIsOnline from "./customHooks/useIsOnline";
import { Provider } from "react-redux";
import { withNamespaces } from "react-i18next";
import ErrorPopup from "@components/partials/ErrorPopup";
import moment from "moment";
import "moment/locale/ar";
//import store
import store from "./store/index";
// import router
import Router from "./Router";
//import i18n
import "./plugins/i18n";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
//import compoents
import Header from "@components/partials/Header.jsx";
import Footer from "@components/partials/Footer.jsx";
import { globalConfigContext } from "@context/GlobalConfigContext";
//CSS Imports
// import '@assets/css/App.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@assets/css/slick.css";
import "react-day-picker/lib/style.css";

import "@assets/css/responsive.css";
// import "@assets/css/style.css";
import "@assets/css/user-profile.css";

import "react-modal-video/css/modal-video.min.css";

import { ToastContainer } from "react-toastify";
import { LanguageService } from "@apiService/tokenService";

const language = LanguageService.getLanguage();

const App = ({ t }) => {
  const isOnline = useIsOnline();

  useEffect(() => {
    // let mainSpinner = document.getElementById('main-spinner');
    // if(mainSpinner) {
    // 	mainSpinner.style.display = "none";
    // }
  }, []);

  return isOnline ? (
    <Provider store={store}>
      <Header />
      <main className="App">
        <Router />
      </main>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
    </Provider>
  ) : (
    <div />
  );
};

export default withNamespaces()(App);
