import React, { useEffect, useState, useRef, useContext } from "react";
import { withNamespaces } from "react-i18next";
import GenericService from "@apiService/GenericService";
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Accordion,
  Card,
} from "react-bootstrap";
import map from "@assets/pictures/svgs/map.svg";
import { motion } from "framer-motion";

const PrivacyPolicy = ({ t }) => {
  function arrowRotate(e) {
    let openedElement = document.querySelector(".card-header.show");
    let collapsingElement = document.querySelector(".collapsing");
    console.log(openedElement);
    const elem = e.target;
    if (openedElement && openedElement !== elem) {
      console.log("entered");
      openedElement.classList.remove("show");
    }
    if (elem.classList.contains("card-header")) {
      if (elem.nextElementSibling.classList.contains("collapse")) {
        if (elem.classList.contains("show")) {
          elem.classList.remove("show");
          elem.classList.add("hide");
        } else if (elem.classList.contains("hide")) {
          elem.classList.remove("hide");
          elem.classList.add("show");
        } else {
          elem.classList.add("show");
        }
      }
    }
  }

  const objArray = [
    {
      question: "First question",
      answer: "First answer",
    },
    {
      question: "Second question",
      answer: "Second answer",
    },
  ];

  useEffect(() => {
    if (document.querySelector(".accordion")) {
      document
        .querySelector(".accordion")
        .addEventListener("click", arrowRotate);
    }
  });

  return (
    <div>
      {/* <div className="px-0">
        <section className="container-fluid container-xl header-space faq">
          <motion.p
            className="title"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            Privacy Policy
          </motion.p>
          <div className="row">
            <div className="col-md-4 d-none d-md-block">
              <div className="side-menu">
                <p className="active">General Information</p>
                <p>Bookings & Payments</p>
                <p>Your Account</p>
                <p>Tickets &amp; Offers</p>
              </div>
            </div>
            <motion.div
              className="col-md-12"
              initial={{ x: -75, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.75 }}
            >
              {objArray && (
                <Accordion defaultActiveKey="0">
                  {objArray.map((item) => (
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey={item}>
                        {item.question}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={item}>
                        <Card.Body>
                          <p>{item.answer}</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                </Accordion>
              )}
            </motion.div>
          </div>
        </section>
      </div> */}
      <div className="container-fluid page header-space">
        <div className="">
          <div className="title">
            <h3>{t("privacy-policy")}</h3>
          </div>
          <div className="content">
            <p>{t("privacy-msg.para1")}</p>
            <p>{t("privacy-msg.para2")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(PrivacyPolicy);
