import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import sampleBg from "@assets/pictures/sample-bg.png";
import { withNamespaces } from "react-i18next";
import { setSelectedMiniFestival } from "../../store/movies/movieActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const FestsNEvents = ({ festival, t }) => {
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { lang_id, lang_name, iso_2 } = selected_language;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    // category_image,
    category_link_image,
    category_link_description,
    category_link_name,
    from_date,
    to_date,
    new_dates,
    total_movies,
    category_link_url,
    language_content,
  } = festival;

  return (
    <motion.div
      className="row fests-n-events"
      initial={{ x: -75, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="col-12">
        <p className="name">
          {language_content?.find((c) => c.lang_id === lang_id)?.event_name}
        </p>
      </div>
      <div className="col-lg-6 mb-4 mb-lg-0">
        <div className="img-card">
          <img
            src={category_link_image}
            alt="Event Banner"
            className="cursor-pointer"
            onClick={() =>
              history.push(`/mini-festivals?mini_fest_id=${festival.unique_id}`)
            }
          />
        </div>
      </div>
      <div className="col-lg-6">
        <div className="d-flex w-100 bb pb-4 mb-4">
          <div className="col-3 col-md-3 col-xl-2 br pr-4">
            <p className="key">{t("Movies")}</p>
            <p className="value">{total_movies}</p>
          </div>
          <div className="col-9 col-md-9 col-xl-10 pl-4">
            <div className="d-flex justify-content-between">
              <div className="">
                <p className="key">{t("Dates")}</p>
                <p className="value">
                  {moment(festival?.from_date).format("Do MMM")} -{" "}
                  {moment(festival?.to_date).format("Do MMM")}
                </p>
              </div>
              <Link
                to={`/mini-festivals?mini_fest_id=${festival.unique_id}`}
                onClick={() => dispatch(setSelectedMiniFestival(festival))}
                className="d-none d-md-block"
              >
                <button className="btn-main btn-main-sm">{t("Watch")}</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              {
                language_content?.find((c) => c.lang_id === lang_id)
                  ?.event_description
              }
            </p>
          </div>
        </div>
        <Link
          to={`/mini-festivals?mini_fest_id=${festival.unique_id}`}
          className="d-block d-md-none mt-4"
        >
          <button className="btn-main btn-main-sm mx-auto">{t("Watch")}</button>
        </Link>
      </div>
    </motion.div>
  );
};

export default withNamespaces()(FestsNEvents);
