import { API_VERSION } from "../config";
import Api from "./Api";

export default {
  GetCountries() {
    return Api().get("/api/external/countries");
  },
  GetFaq() {
    return Api().get(`api/external/faq-list`);
  },
  GetNewsLetter(payload) {
    return Api().post(`api/external/news-letter`, payload);
  },
  GetMsPage(pageId) {
    return Api().get(`api/external/ms_page?page_id=${pageId}`);
  },
};
