import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";

const TnC = ({ t }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid page header-space">
      <div className="">
        <div className="title">
          <h3>{t("disclaimer")}</h3>
        </div>
        <div className="content">
          <p>{t("disclaimer-msg.para")}</p>

          <h4 className="mt-5 mb-n4">{t("disclaimer-msg.title1")}</h4>
          <p>{t("disclaimer-msg.para1")}</p>
          <h4 className="mt-5 mb-n4">{t("disclaimer-msg.title2")}</h4>
          <p>{t("disclaimer-msg.para2")}</p>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(TnC);
