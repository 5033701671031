import React, { useEffect, useState, useRef, useContext } from "react";
import { Row, Container, Col, Dropdown, Modal, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import MovieCard from "./partials/MovieCard.jsx";
import MovieShow from "./partials/movieShow.jsx";
import {
  fetchMovieDetails,
  fetchMovieSchedules,
  setStage,
  setBookingType,
  privateScreeningReserveSeat,
  fetchOtherScreenings,
  setFromMoviePage,
  fetchScreeningTypes,
  fetchScheduleDetails,
} from "../store/movies/movieActions";
import ScreeningCard from "./partials/screeningCard.jsx";
import ScreeningCardNew from "./partials/screeningCardNew.jsx";
import poster from "@assets/pictures/poster.png";
import calendar from "@assets/pictures/svgs/calendar.svg";
import eye from "@assets/pictures/svgs/eye.svg";
import clock from "@assets/pictures/svgs/clock.svg";
import globe from "@assets/pictures/svgs/globe.svg";
import people from "@assets/pictures/svgs/people.svg";
import activePeople from "@assets/pictures/svgs/people-active.svg";
import login from "@assets/pictures/svgs/login.svg";
import activeLogin from "@assets/pictures/svgs/login-active.svg";
import play from "@assets/pictures/svgs/play.svg";
import eyeGreen from "@assets/pictures/svgs/eye-green.svg";
import sandClock from "@assets/pictures/svgs/sand-clock.svg";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useParams } from "react-router";
import moment from "moment";
import arrowBack from "@assets/pictures/svgs/arrow-back.svg";
import arrowBackActive from "@assets/pictures/svgs/arrow-back-active.svg";
import DatePicker from "react-date-picker";
import calendarWhite from "@assets/pictures/svgs/calendar-white.svg";
import close from "@assets/pictures/svgs/close.svg";
import poster2 from "@assets/pictures/poster-2.png";
import Spinner from "./partials/Spinner";
import ShowDetailsPopup from "./partials/popups/showDetailsPopup.jsx";
import ModalVideo from "react-modal-video";
import MovieFooter from "./partials/movieFooter";
import useIsMobile from "../customHooks/useIsMobile";
import SpecialInstructionsPopup from "./partials/popups/SpecialInstructionsPopup";
import dummyPoster from "@assets/pictures/dummyPoster.png";
import { getLangSpecificAttribute } from "@helper/languages";

const ScreeningType = ({ match, t }) => {
  const history = useHistory();
  // const [specialRequest, setSpecialRequest] = useState("");
  const [showPrivateScreenPopup, setShowPrivateScreenPopup] = useState(false);
  const [tempPrivateShowDetails, setTempPrivateShowDetails] = useState(null);
  const isPrivateShowClicked = useRef(false);
  const onConfirmPrivateScreeningPopup = (specialRequest) => {
    // dispatch(setPrivateScreeningMessage(specialRequest));
    if (isPrivateShowClicked.current === false) {
      isPrivateShowClicked.current = true;
      console.log("private show reserved");
      dispatch(
        privateScreeningReserveSeat({
          screen_id: tempPrivateShowDetails.screen_id,
          history,
          mycinema_ss_d_id,
          category_id: 1,
          cinema_id: tempPrivateShowDetails.cinema_id,
          movie_id,
          content_lang_id,
          genre_id,
          isPrivate,
          specialRequest,
        })
      );
    }
  };
  const [back, setBack] = useState(false);
  const {
    movie_id,
    content_lang_id,
    category_id,
    genre_id,
    mycinema_ss_d_id,
  } = useParams();
  const { isPrivate } = match.params;
  // console.log("isPrivate :>> ", isPrivate);
  const dispatch = useDispatch();
  const [value, onChange] = useState(new Date());
  useEffect(() => {
    // dispatch(fetchMovieDetails({ movie_id, content_lang_id }));
    // dispatch(fetchMovieSchedules({ movie_id, category_id }));
    // dispatch(fetchOtherScreenings({ genre_id, movie_id }));
    dispatch(
      fetchScreeningTypes({
        mycinema_ss_d_id,
        payload: { stage: 2, show: null, history },
      })
    );
    dispatch(fetchScheduleDetails({ mycinema_ss_d_id }));
  }, []);
  const movieDetails = useSelector((state) => state.movies.movieDetails);
  const [cardSelected, setCardSelected] = useState(false);
  // useEffect(() => {
  //   if (movieDetails && category_id == 2) {
  //     dispatch(fetchOtherScreenings({ genre_id: 2 }));
  //   }
  // }, [movieDetails, category_id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const screeningTypes = useSelector((state) => state.movies.screeningTypes);
  const screeningTypesLoading = useSelector(
    (state) => state.movies.screeningTypesLoading
  );

  useEffect(() => {
    if (
      (!screeningTypes || screeningTypes.length === 0) &&
      !screeningTypesLoading
    ) {
      // history.go(-1);
    }
  }, [screeningTypes]);
  // const mycinema_ss_d_id = useSelector(
  //   (state) => state.movies.myCinemaCreateShow.mycinema_ss_d_id
  // );
  const show = useSelector((state) => state.movies.show);
  const otherScreeningsMovies = useSelector(
    (state) => state.movies.otherScreenings
  );
  const [isOpen, setOpen] = useState(false);
  const onSelectScreeningType = (type, showName) => {
    console.log("type :>> ", type);
    dispatch(setBookingType(type));
    if (type === 1 || type === 2) {
      dispatch(setFromMoviePage());
      history.push(`/create-show-seat-layout/${mycinema_ss_d_id}/${type}`);
    } else {
      setShowPrivateScreenPopup(true);
      console.log("showName :>> ", showName);
      let tempShow;
      if (showName) {
        tempShow = showName;
      } else {
        tempShow = show;
      }
      console.log("show :>> ", show);
      setTempPrivateShowDetails(tempShow);
      // dispatch(
      //   privateScreeningReserveSeat({
      //     screen_id: tempShow.screen_id,
      //     history,
      //     mycinema_ss_d_id,
      //     category_id: 1,
      //     cinema_id: tempShow.cinema_id,
      //     movie_id,
      //     content_lang_id,
      //     genre_id,
      //     isPrivate,
      //   })
      // );
    }
  };
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { lang_name, iso_2, lang_id } = selected_language;
  useEffect(() => {
    if (!movieDetails) {
      // alert("hi");
      const spinner = document.getElementById("main-spinner");
      if (spinner) {
        spinner.style.display = "flex";
        setTimeout(() => (spinner.style.display = "none"), 1000);
      }
      // else {
      //   alert("hi2");
      //   spinner.style.display = "none";
      // }
    }
  }, [movieDetails]);

  const movieSchedules = useSelector((state) => state.movies.movieSchedules);
  const movieSchedulesLoading = useSelector(
    (state) => state.movies.movieSchedulesLoading
  );
  const movieScheduleDetails = useSelector(
    (state) => state.movies.movieScheduleDetails
  );
  const onChangeCalenderDate = (value) => console.log(value);
  const todaysScreeningsMovies = useSelector(
    (state) => state.movies.todaysScreenings
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCinema, setSelectedCinema] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedLanguage2, setSelectedLanguage2] = useState(null);
  const [selectedFormat2, setSelectedFormat2] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [peopleSvg, setPeopleSvg] = useState(true);
  const [loginSvg, setLoginSvg] = useState(true);
  useEffect(() => {
    if (movieScheduleDetails?.dateList?.length > 0) {
      setSelectedDate(movieScheduleDetails.dateList[0].ss_actual_start_date);
    }
  }, [movieScheduleDetails]);
  // const dateSlider = {
  //   arrows: true,
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 6,
  //   slidesToScroll: 6,
  // };
  const dateSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
  };
  const onChangeDate = (date) => {
    console.log("date :>> ", date);
    const { ss_actual_start_date } = date;
    dispatch(
      fetchMovieSchedules({
        movie_id,
        category_id,
        dateSchedule: ss_actual_start_date,
        cinema_id: selectedCinema?.cinema_id,
        lang_id: selectedLanguage?.lang_id,
        mf_id: selectedFormat?.mf_id,
      })
    );
    setSelectedDate(ss_actual_start_date);
  };
  const onChangeCinema = (cinema) => {
    const { cinema_id } = cinema;
    dispatch(
      fetchMovieSchedules({
        movie_id,
        category_id,
        cinema_id,
        dateSchedule: moment(selectedDate).format("YYYY-MM-DD"),
        lang_id: selectedLanguage?.lang_id,
        mf_id: selectedFormat?.mf_id,
      })
    );
    setSelectedCinema(cinema);
  };
  const onChangeLanguage = (language) => {
    const { lang_id } = language;

    setSelectedLanguage2(language);
    // setModalShow(false);
  };
  const onConfirmShowDetailsPopup = () => {
    setSelectedLanguage(selectedLanguage2);
    setSelectedFormat(selectedFormat2);
    dispatch(
      fetchMovieSchedules({
        movie_id,
        category_id,
        cinema_id: selectedCinema?.cinema_id,
        dateSchedule: moment(selectedDate).format("YYYY-MM-DD"),
        lang_id: selectedLanguage?.lang_id,
        mf_id: selectedFormat?.mf_id,
      })
    );
    setModalShow(false);
  };

  const onChangeFormat = (format) => {
    const { mf_id } = format;
    // dispatch(
    //   fetchMovieSchedules({
    //     movie_id,
    //     category_id,
    //     cinema_id: selectedCinema?.cinema_id,
    //     dateSchedule: moment(selectedDate).format("YYYY-MM-DD"),
    //     lang_id: selectedLanguage?.lang_id,
    //     mf_id,
    //   })
    // );
    setSelectedFormat2(format);
    // setModalShow(false);
  };
  // const [stage, setStage] = useState(1);
  // const stage = useSelector((state) => state.movies.stage);
  const stage = 2;
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [calenderDateSelected, setCalenderDateSelected] = useState(false);
  const isMobile = useIsMobile();

  const movieSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      otherScreeningsMovies.length > 6 ? 6 : otherScreeningsMovies.length,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: false,
        },
      },
    ],
  };

  const showDetails = {
    movieImg: poster2,
    tags: ["Family", "Children", "3D MAX"],
    features: ["2019", "FSK 18", "1h10min", "DE"],
    languages: ["English", "French", "Spanish", "Greek"],
    formats: ["2D", "3D", "IMAX"],
  };

  return (
    <div className="">
      {/* <ShowDetailsPopup
        showDetails={showDetails}
        closeFunc={setModalShow}
        onHide={() => setModalShow(false)}
      /> */}
      {/* POPUP START */}
      <Modal
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        className="show-details-popup"
        centered
        onHide={() => setModalShow(false)}
      >
        <section className="wrapper container-fluid">
          <img
            src={close}
            className="close-btn-main"
            onClick={() => {
              setModalShow(false);
              if (!selectedLanguage) {
                setSelectedLanguage2(null);
              }
              if (!selectedFormat) {
                setSelectedFormat2(null);
              }
            }}
            alt=""
          />

          <div className="row align-items-center">
            <div className="col-sm-4 d-none d-md-block">
              <div className="poster-img">
                <img
                  src={
                    getLangSpecificAttribute(
                      movieDetails?.languageSpecificImages,
                      lang_id,
                      "artwork"
                    ) || dummyPoster
                  }
                  alt="Poster 2"
                  className=""
                />
              </div>
            </div>
            <div className="col-sm-8">
              <h4 className="heading">
                {getLangSpecificAttribute(
                  movieDetails?.altMovieContent,
                  lang_id,
                  "mc_title"
                ) || movieDetails?.movie_title}
              </h4>
              <div className="tags">
                {/* {showDetails.tags.map((tag) => (
                  <span className="">{tag}</span>
                ))} */}
                <span>{movieDetails?.genre}</span>
                <span>{movieDetails?.mf_name}</span>
              </div>
              <div className="features">
                <div className="features-part">
                  {/* {showDetails.features.map((feature) => (
                    <div>
                      <img src={calendar} alt="calendar" />
                      <span>{feature}</span>
                    </div>
                  ))} */}
                  <div>
                    <img src={calendar} alt="calendar" />
                    <span>
                      {moment(movieDetails?.mrrdr_release_date).format("YYYY")}
                    </span>
                  </div>
                  <div>
                    <img src={eye} alt="calendar" />
                    <span>{movieDetails?.rating}</span>
                  </div>
                  <div>
                    <img src={clock} alt="calendar" />
                    <span>
                      {movieDetails?.mrrdr_runtime} {t("common.mins")}
                    </span>
                  </div>
                  <div>
                    <img src={globe} alt="calendar" />
                    <span>{t("common.DE")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="px-3 w-100">
              <h6 className="sub-heading">{t("Choose Language")}</h6>
              <div className="btn-main-group">
                {movieScheduleDetails?.LanguageArray.map((language, index) => (
                  // add class active in button element when selected
                  <button
                    className={`tile ${
                      language.lang_id === selectedLanguage2?.lang_id &&
                      "active"
                    }`}
                    onClick={() => onChangeLanguage(language)}
                    key={index}
                  >
                    {language.lang_name}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="px-3 w-100">
              <h6 className="sub-heading">{t("Choose Format")}</h6>
              <div className="btn-main-group">
                {movieScheduleDetails?.FormatArray.map((format, index) => (
                  // add class active in button element when selected
                  <button
                    className={`tile format ${
                      format.mf_id === selectedFormat2?.mf_id && "active"
                    }`}
                    onClick={() => onChangeFormat(format)}
                    key={index}
                  >
                    {format.mf_name}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="row pt-2">
            <div className="px-3 mt-4 mx-auto">
              {/* add class inactive when it is not selectable */}
              <button
                className="btn-main"
                onClick={() => onConfirmShowDetailsPopup()}
              >
                {t("Confirm")}
              </button>
            </div>
          </div>
        </section>
      </Modal>
      {/* POPUP END */}
      <div className="container-fluid container-xl header-space movie-footer-space">
        {category_id == 2 ? (
          <div className="row movie-container">
            {!isMobile ? (
              <>
                <div className="col-6 col-sm-4 col-md-3 mb-4 mb-xl-0">
                  <div className="img-card">
                    <img
                      src={
                        getLangSpecificAttribute(
                          movieDetails?.languageSpecificImages,
                          lang_id,
                          "artwork"
                        ) || dummyPoster
                      }
                      alt=""
                      className=""
                    />
                  </div>
                </div>
                <div className="col-xl-9">
                  <div className="row">
                    <div className="col-md-6 mb-4 mb-md-0">
                      <div className="tags">
                        <span className="">{movieDetails?.genre}</span>
                        {/* <span>Children</span> */}
                        <span>{movieDetails?.mf_name}</span>
                      </div>
                      <p className="movie-name">
                        {getLangSpecificAttribute(
                          movieDetails?.altMovieContent,
                          lang_id,
                          "mc_title"
                        ) || movieDetails?.movie_title}
                      </p>
                      <div className="movie-features">
                        <div className="">
                          <img src={calendar} alt="calendar" />
                          <span>
                            {moment(movieDetails?.mrrdr_release_date).format(
                              "YYYY"
                            )}
                          </span>
                        </div>
                        <div className="">
                          <img src={eye} alt="calendar" />
                          <span>{movieDetails?.rating}</span>
                        </div>
                        <div className="">
                          <img src={clock} alt="calendar" />
                          <span>
                            {movieDetails?.mrrdr_runtime} {t("common.mins")}
                          </span>
                        </div>
                        <div className="">
                          <img src={globe} alt="calendar" />
                          <span>{t("common.DE")}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 movie-details">
                      <div className="row mb-3">
                        <div className="col-4">
                          <p className="detail-title">Stars</p>
                        </div>
                        <div className="col-8">
                          <p>{movieDetails?.movie_cast_crew}</p>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-4">
                          <p className="detail-title">Languages</p>
                        </div>
                        <div className="col-8">
                          {movieDetails?.trailer_array?.map((lang, index) => (
                            <p key={index}>{lang.lang_name}</p>
                          ))}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-4">
                          <p className="detail-title">{t("common.Trailers")}</p>
                        </div>
                        <div className="col-8">
                          {movieDetails?.trailer_array?.map(
                            (trailer, index) => (
                              // trailer?.mt_link
                              <span className="mr-4" key={index}>
                                <img
                                  src={play}
                                  alt="play"
                                  onClick={() => setOpen(true)}
                                  className="mr-2 play-trailer"
                                />
                                <ModalVideo
                                  autoplay
                                  channel="youtube"
                                  isOpen={isOpen}
                                  videoId={trailer?.mt_link.split("=")[1]}
                                  onClose={() => setOpen(false)}
                                />
                                {/* <button
                            className="btn-primary"
                            
                          >
                            VIEW DEMO
                          </button> */}
                                {trailer?.lang_name}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                      <p className="text-limit">{movieDetails?.mc_plot}</p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="px-3 d-flex">
                      <Link
                        to={`/movie-details/${movie_id}/${content_lang_id}`}
                        onClick={() => {
                          const spinner = document.getElementById(
                            "main-spinner"
                          );
                          if (spinner) {
                            spinner.style.display = "flex";
                          } else {
                            spinner.style.display = "none";
                          }
                        }}
                      >
                        <button
                          className="btn-main mr-3 md"
                          onMouseOver={() => setPeopleSvg(false)}
                          onMouseOut={() => setPeopleSvg(true)}
                        >
                          {" "}
                          <img
                            src={peopleSvg ? people : activePeople}
                            alt="people"
                            className="mr-2"
                            // onClick={() =>
                            //   history.push(

                            //   )
                            // }
                          />{" "}
                          Your own Screening
                        </button>
                      </Link>
                      <Link
                        to={`/movie-details/${movie_id}/${content_lang_id}`}
                      >
                        <button
                          className="btn-main md"
                          onMouseOver={() => setLoginSvg(false)}
                          onMouseOut={() => setLoginSvg(true)}
                        >
                          <img
                            src={loginSvg ? login : activeLogin}
                            alt="people"
                            className="mr-2"
                          />
                          Private Screening
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              /* Mobile Specific Starts */
              <>
                <div className="col-12">
                  <p className="movie-name">
                    {getLangSpecificAttribute(
                      movieDetails?.altMovieContent,
                      lang_id,
                      "mc_title"
                    ) || movieDetails?.movie_title}
                  </p>
                  <div className="img-card my-4">
                    <img
                      src={
                        getLangSpecificAttribute(
                          movieDetails?.languageSpecificImages,
                          lang_id,
                          "artwork"
                        ) || dummyPoster
                      }
                      alt=""
                      className=""
                    />
                  </div>
                  <div className="mb-4">
                    {movieDetails?.trailer_array?.map((trailer, index) => (
                      <p className="mr-4 play-trailer" key={index}>
                        <img
                          src={play}
                          alt="play"
                          onClick={() => setOpen(true)}
                          className="mr-2"
                        />
                        <ModalVideo
                          autoplay
                          channel="youtube"
                          isOpen={isOpen}
                          videoId={trailer?.mt_link?.split("=")[1]}
                          onClose={() => setOpen(false)}
                        />
                        {/* <button
                            className="btn-primary"
                            
                          >
                            VIEW DEMO
                          </button> */}
                        {trailer?.lang_name}
                      </p>
                    ))}
                  </div>
                  <div className="tags mb-4">
                    <span className="">{movieDetails?.genre}</span>
                    {/* <span>Children</span> */}
                    <span>{movieDetails?.mf_name}</span>
                  </div>
                  <div className="d-flex flex-wrap">
                    <div className="movie-features flex-fill">
                      <div className="">
                        <img src={calendar} alt="calendar" />
                        <span>
                          {moment(movieDetails?.mrrdr_release_date).format(
                            "YYYY"
                          )}
                        </span>
                      </div>
                      <div className="">
                        <img src={eye} alt="calendar" />
                        <span>{movieDetails?.rating}</span>
                      </div>
                    </div>
                    <div className="movie-features flex-fill">
                      <div className="">
                        <img src={clock} alt="calendar" />
                        <span>{movieDetails?.mrrdr_runtime} mins</span>
                      </div>
                      <div className="">
                        <img src={globe} alt="calendar" />
                        <span>DE</span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 movie-details">
                    <div className="col-4">
                      <p className="detail-title">{t("common.Stars")}</p>
                    </div>
                    <div className="col-8">
                      <p>{movieDetails?.movie_cast_crew}</p>
                    </div>
                  </div>
                  <div className="row mb-3 movie-details">
                    <div className="col-4">
                      <p className="detail-title">{t("common.Languages")}</p>
                    </div>
                    <div className="col-8">
                      {movieDetails?.trailer_array?.map((lang, index) => (
                        <p key={index}>{lang.lang_name}</p>
                      ))}
                    </div>
                  </div>
                  <p className="text-limit">{movieDetails?.mc_plot}</p>
                  <div className="row mt-3">
                    <div className="px-3 d-flex">
                      <Link
                        to={`/movie-details/${movie_id}/${content_lang_id}`}
                        onClick={() => {
                          const spinner = document.getElementById(
                            "main-spinner"
                          );
                          if (spinner) {
                            spinner.style.display = "flex";
                          } else {
                            spinner.style.display = "none";
                          }
                        }}
                      >
                        <button
                          className="btn-main mr-3 md"
                          onMouseOver={() => setPeopleSvg(false)}
                          onMouseOut={() => setPeopleSvg(true)}
                        >
                          {" "}
                          <img
                            src={peopleSvg ? people : activePeople}
                            alt="people"
                            className="mr-2"
                            // onClick={() =>
                            //   history.push(

                            //   )
                            // }
                          />{" "}
                          {t("Your own Screening")}
                        </button>
                      </Link>
                      <Link
                        to={`/movie-details/${movie_id}/${content_lang_id}`}
                      >
                        <button
                          className="btn-main md"
                          onMouseOver={() => setLoginSvg(false)}
                          onMouseOut={() => setLoginSvg(true)}
                        >
                          <img
                            src={loginSvg ? login : activeLogin}
                            alt="people"
                            className="mr-2"
                          />
                          {t("Private Screening")}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
              /* Mobile Specific Ends */
            )}
          </div>
        ) : stage === 2 ? (
          <>
            {/*<div className="container-fluid container-xl header-space"> */}
            <p className="mx-auto big-heading text-center">
              {t("Select Screening Type")}
            </p>
            <section className="row justify-content-center for-prev-step">
              {stage !== 1 && (
                <div className="prev-step">
                  <button
                    className="btn-main mx-auto mt-3 mt-xl-4"
                    onClick={() => history.go(-1)}
                    onMouseOver={() => setBack(true)}
                    onMouseOut={() => setBack(false)}
                  >
                    <img
                      src={back ? arrowBackActive : arrowBack}
                      className="mr3"
                      alt=""
                    />
                    {/* Back */}
                  </button>
                </div>
              )}

              <div className="col-sm-9">
                <div className="steps">
                  <div
                    className={`step ticket ${
                      stage === 1
                        ? "complete"
                        : stage === 2
                        ? "complete"
                        : stage === 3
                        ? "complete"
                        : ""
                    }`}
                  >
                    {/* <img src={ticketRed} /> */}
                  </div>
                  <div
                    className={`step screen ${
                      stage === 2 ? "complete" : stage === 3 ? "complete" : ""
                    }`}
                  >
                    {/* <img src={ticketRed} /> */}
                  </div>
                  <div className={`step seat ${stage === 3 ? "complete" : ""}`}>
                    {/* <img src={ticketRed} /> */}
                  </div>
                </div>
              </div>
              {/* </div> */}
            </section>
            {/* </div> */}
          </>
        ) : null}
        <>
          {/* <section className="row align-items-center">
            {screeningTypes.map((screen, index) => (
              <ScreeningCard
                cardType="basic"
                onClick={(type) => onSelectScreeningType(type)}
                key={index}
                screen={screen}
                isUnconfirmedAvailable={show?.isUnconfirmedAvailable}
              />
            ))}
          </section> */}
          {screeningTypes.length > 0 && (
            <ScreeningCardNew
              screeningTypes={screeningTypes}
              isUnconfirmedAvailable={show?.isUnconfirmedAvailable}
              passedFunc={(type) => onSelectScreeningType(type)}
              setCardSelected={setCardSelected}
              cardSelected={cardSelected}
            />
          )}
        </>
        {stage === 1 && (
          <>
            <section className="row date-picker-section">
              <div className="col-xl-5 mb-4 mb-xl-0 px-xl-4">
                {movieScheduleDetails?.dateList && (
                  <Slider {...dateSlider} className="date-slider">
                    {movieScheduleDetails?.dateList.map(
                      (date, index) =>
                        index < 7 && (
                          <div key={index}>
                            <span
                              className={`date-section ${
                                date.ss_actual_start_date === selectedDate &&
                                "selected"
                              }`}
                              onClick={() => onChangeDate(date)}
                            >
                              <p className="month">{date.momthName}</p>
                              <p className="date">{date.day}</p>
                              <p className="day">{date.dayName}</p>
                            </span>
                          </div>
                        )
                    )}
                  </Slider>
                )}
              </div>

              <div className="col-xl-7">
                <div className="d-flex justify-content-between align-items-center h-100">
                  <div className="config">
                    <DatePicker
                      formatShortWeekday={(locale, date) =>
                        moment(date).locale(locale).format("dd").substr(0, 1)
                      }
                      locale={iso_2?.toLowerCase()}
                      onChange={(value) => {
                        setCalenderDateSelected(true);
                        onChange(value);
                        onChangeDate({
                          ss_actual_start_date: moment(value).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      }}
                      value={value}
                      className="date-picker-popup"
                      isOpen={calendarPopup}
                      onCalendarClose={() => setCalendarPopup(false)}
                    />
                    <img
                      src={calendarWhite}
                      className="md-icon mr-2 mr-md-3"
                      alt=""
                    />
                    <span
                      className="title"
                      onClick={() => {
                        calendarPopup
                          ? setCalendarPopup(false)
                          : setCalendarPopup(true);
                      }}
                    >
                      {!calenderDateSelected
                        ? "Calender"
                        : moment(value).format("ll")}
                    </span>
                  </div>

                  <div className="config">
                    <div className="dropdown-bg">
                      <Dropdown className="multiselect">
                        <Dropdown.Toggle
                          className="dropdownClassic"
                          id="dropdown-basic"
                        >
                          <span className="title">
                            {selectedCinema
                              ? selectedCinema.cinema_name
                              : "Choose Cinema"}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="right" className="dropdownItems">
                          {movieScheduleDetails?.CinemaArray?.map(
                            (cinema, index) => (
                              <Dropdown.Item
                                className={
                                  selectedCinema?.cinema_id ===
                                    cinema?.cinema_id && "selected"
                                }
                                onClick={() => onChangeCinema(cinema)}
                                key={index}
                              >
                                {cinema.cinema_name}
                              </Dropdown.Item>
                            )
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="config d-none d-md-flex">
                    {/*<div className="dropdown-bg">
                      <Dropdown className="multiselect">
                        <Dropdown.Toggle
                          className="dropdownClassic"
                          id="dropdown-basic"
                        >
                          <span className="title">English 2D</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="right" className="dropdownItems">
                          <Dropdown.Item href="#/action-1" className="">
                            ENGLISH
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2" className="">
                            SANSKRIT
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">ARAB</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>*/}
                    <span
                      className="title show-details-trigger"
                      onClick={() => setModalShow(true)}
                    >
                      {selectedLanguage
                        ? selectedLanguage?.lang_name === "All"
                          ? "Language"
                          : selectedLanguage?.lang_name
                        : "Language"}{" "}
                      {selectedFormat
                        ? selectedLanguage?.lang_name === "All"
                          ? "Format"
                          : selectedFormat.mf_name
                        : "Format"}
                    </span>
                  </div>

                  {/* fixed settings icon for mobile starts */}
                  <div
                    className="settings-icon"
                    onClick={() => setModalShow(true)}
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.5 0C12.2239 0 12 0.223857 12 0.5V3.627C11.0002 3.95 10.0533 4.39081 9.17575 4.93309L7.16115 2.91853C6.9659 2.72327 6.6493 2.72327 6.45405 2.91853L2.21142 7.16115C2.01616 7.35645 2.01616 7.673 2.21142 7.8683L4.4231 10.08C3.95389 10.9957 3.59014 11.9743 3.34787 13H0.5C0.223857 13 0 13.2239 0 13.5V19.5C0 19.7761 0.223857 20 0.5 20H3.627C3.84184 20.6651 4.10881 21.3066 4.4231 21.92L2.21142 24.1318C2.01616 24.327 2.01616 24.6436 2.21142 24.8389L6.45405 29.0814C6.6493 29.2767 6.9659 29.2767 7.16115 29.0814L9.17575 27.0669C10.0533 27.6092 11.0002 28.05 12 28.373V31.5C12 31.7761 12.2239 32 12.5 32H18.5C18.7761 32 19 31.7761 19 31.5V28.6521C20.0257 28.4098 21.0043 28.0461 21.92 27.5769L24.1318 29.7886C24.327 29.9839 24.6436 29.9839 24.8389 29.7886L29.0814 25.5459C29.2767 25.3507 29.2767 25.0341 29.0814 24.8389L27.0669 22.8242C27.6092 21.9467 28.05 20.9998 28.373 20H31.5C31.7761 20 32 19.7761 32 19.5V13.5C32 13.2239 31.7761 13 31.5 13H28.6521C28.3281 11.6286 27.787 10.341 27.0669 9.17575L29.0814 7.16115C29.2767 6.9659 29.2767 6.6493 29.0814 6.45405L24.8389 2.21142C24.6436 2.01616 24.327 2.01616 24.1318 2.21142L21.92 4.4231C21.0043 3.95389 20.0257 3.59014 19 3.34787V0.5C19 0.223857 18.7761 0 18.5 0H12.5ZM16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24Z"
                        fill="#E70D00"
                      />
                    </svg>
                  </div>
                  {/* settings icon ends */}
                </div>
              </div>
            </section>
            {/* Markings */}
            <div className="row show-status">
              <div className="px-3 mx-auto">
                <ul className="list-inline mb-0 d-flex">
                  <li className="list-inline-item">
                    <div className="d-flex align-items-center">
                      <img src={eyeGreen} alt="green eye" className="mr-3" />
                      <p>{t("Confirmed Show | minimum seats booked")}</p>
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <div className="d-flex align-items-center">
                      <img src={sandClock} alt="sand clock" className="mr-3" />
                      <p>{t("Less than minimum seats booked")}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* Shows */}
            {movieSchedulesLoading ? (
              <div className="row">
                <div className="col-12 text-center">
                  <Spinner />
                </div>
              </div>
            ) : movieSchedules?.MovieListArray?.length === 0 ? (
              <div className="row">
                <div className="col-12 text-center">
                  <h4>{t("No shows found")}</h4>
                </div>
              </div>
            ) : (
              movieSchedules?.MovieListArray?.[0]?.CinemaList.map(
                (cinema, index) => (
                  <MovieShow
                    key={index}
                    theatre={cinema.cinema_name}
                    theatreAddress={`${cinema?.cine_address}`}
                    languages={cinema.format_list}
                    shows={cinema.format_list[index].schedule_list}
                    privateCallback={(type, item) =>
                      onSelectScreeningType(3, item)
                    }
                    isPrivate={isPrivate}
                    movie_id={movie_id}
                    content_lang_id={content_lang_id}
                    category_id={category_id}
                    genre_id={genre_id}
                    cinemaIndex={index}
                  />
                )
              )
            )}
            {/* {} */}

            {category_id == 2 && (
              <section className="row">
                <div className="movie-footer">
                  <div className="col-4">
                    <div className="movie-img-n-details">
                      <div className="img-wrapper">
                        <img
                          src={
                            getLangSpecificAttribute(
                              movieDetails?.languageSpecificImages,
                              lang_id,
                              "artwork"
                            ) || dummyPoster
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="movie-title">
                          {getLangSpecificAttribute(
                            movieDetails?.altMovieContent,
                            lang_id,
                            "mc_title"
                          ) || movieDetails?.movie_title}
                        </p>
                        <p className="movie-details">
                          {selectedLanguage?.lang_name}{" "}
                          {selectedFormat?.mf_name}
                        </p>
                        <p className="movie-details">
                          <span>{movieDetails?.genre} | </span>
                          <span>{movieDetails?.rating}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    {selectedCinema && (
                      <>
                        <p className="movie-field-title">{t("Cinema")}:</p>
                        <p className="movie-field">
                          {selectedCinema.cinema_name}
                        </p>
                      </>
                    )}
                    {selectedDate && (
                      <>
                        <p className="movie-field-title">
                          Date {false && `& Time:`}
                        </p>
                        <p className="movie-field">
                          {moment(selectedDate).format("ll")}
                        </p>
                      </>
                    )}
                  </div>
                  {/* <div className="col-2">
                  <p className="movie-field-title">Screening type:</p>
                  <p className="movie-field">Private</p>
                  <p className="movie-field-title">Show:</p>
                  <p className="movie-field">Some show</p>
                </div> */}
                  {/* <div className="col-3">
                  <p className="movie-field-title">Total amount:</p>
                  <p className="movie-field-title">
                    (Amount inclusive of all taxes)
                  </p>
                  <p className="total-price">TBD</p>
                </div> */}
                </div>
              </section>
            )}

            {category_id == 2 && (
              <>
                {" "}
                {todaysScreeningsMovies?.length > 0 && (
                  <div className="row movie-slider-heading">
                    <div className="px-3">
                      <span className="">{t("Other Screenings")}</span>
                    </div>
                  </div>
                )}
                {/* Movies List 1*/}
                <div className="row">
                  <div className="col-12 px-0">
                    {otherScreeningsMovies?.length > 0 && (
                      <Slider {...movieSlider} className="movie-slider">
                        {otherScreeningsMovies?.map((movie, index) => (
                          <div key={index}>
                            <MovieCard {...movie} key={index} />
                          </div>
                        ))}
                      </Slider>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <MovieFooter
        movie_title={
          getLangSpecificAttribute(
            movieDetails?.altMovieContent,
            lang_id,
            "mc_title"
          ) || movieDetails?.movie_title
        }
        image={
          getLangSpecificAttribute(
            movieDetails?.languageSpecificImages,
            lang_id,
            "artwork"
          ) || dummyPoster
        }
        lang={movieDetails?.lang_name}
        format={movieDetails?.mf_name}
        rating={movieDetails?.rating}
        genre={
          movieDetails?.genres_with_translations?.[0]?.[iso_2?.toLowerCase()] ||
          movieDetails?.genre
        }
        cinemaName={show?.cinema_name}
        cine_location={show?.cine_location}
        cine_address={show?.cine_address}
        date={show?.ss_start_date}
        time={show?.ss_start_show_time}
        showType={
          show?.showType === 2
            ? "Unconfirmed"
            : show?.showType === 1
            ? "Confirmed"
            : null
        }
        category_id={category_id}
      />
      <SpecialInstructionsPopup
        showPrivateScreenPopup={showPrivateScreenPopup}
        onConfirmPrivateScreeningPopup={onConfirmPrivateScreeningPopup}
        closeFunc={() => {
          setShowPrivateScreenPopup(false);
          setCardSelected(false);
        }}
      />
    </div>
  );
};

export default withNamespaces()(ScreeningType);
