import React, { useEffect, useState, useRef, useContext } from "react";
import { withNamespaces } from "react-i18next";
import GenericService from "@apiService/GenericService";
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Accordion,
  Card,
  Button,
} from "react-bootstrap";
import offer from "@assets/pictures/offer.png";
import { motion } from "framer-motion";
import { fetchOffers } from "../store/ancilliary/ancilliaryActions";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "./partials/Spinner";

const PromotionsAndOffers = ({ t }) => {
  const dispatch = useDispatch();
  useEffect(() => dispatch(fetchOffers()), []);
  const offers = useSelector((state) => state.ancilliary.offers);
  const offersLoading = useSelector((state) => state.ancilliary.offersLoading);
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  return (
    <div>
      <div className="px-0">
        <section className="container-fluid container-xl header-space pno">
          <motion.p
            className="title"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            {t("Promotions & Offers")}
          </motion.p>
          <div className="row">
            <div className="col-12">
              {offersLoading ? (
                <div className="row my-2">
                  <div className="col-12 text-center">
                    <Spinner />
                  </div>
                </div>
              ) : offers?.length === 0 ? (
                <div className="row my-2">
                  <div className="col-12 text-center">
                    <div>{t("home_page.No Results Found")}</div>
                  </div>
                </div>
              ) : (
                offers?.map((item, index) => (
                  <>
                    <Accordion defaultActiveKey="0">
                      <Card key={index}>
                        <Card.Header>
                          <motion.div
                            className="offer"
                            initial={{ x: -75, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ duration: 0.75 }}
                          >
                            <div className="row w-100 mx-0 mr-lg-3">
                              <div className="col-lg-6 mb-4 mb-lg-0 px-0 pr-lg-3">
                                <div className="img-wrapper">
                                  <img src={offer} alt="offer" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="offer-content">
                                  <p className="offer-name">
                                    {item?.offer_title}
                                  </p>
                                  {/* <p className="offer-desc">Offer Description</p> */}
                                  <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey={item}
                                  >
                                    <button className="btn-main mb-4 mb-lg-0">
                                      {t("common.Load More")}
                                    </button>
                                  </Accordion.Toggle>
                                </div>
                              </div>
                            </div>
                          </motion.div>
                        </Card.Header>
                        <Accordion.Collapse eventKey={item}>
                          <Card.Body>
                            <div className="offer-tnc">
                              <p className="a">Terms &amp; Conditions</p>
                              <ul className="">
                                {/* {[1, 2, 3, 4].map((item) => (
                              <li className="b">
                                This promotion is not to be combined with other
                                offers or discounts
                              </li>
                            ))} */}
                                {item.offer_type_id == 1 && (
                                  <>
                                    <li className="b">
                                      Minimum Ticket Price:{" "}
                                      {item.oc_min_ticket_price}
                                    </li>
                                    <li className="b">
                                      Maximum Free Tickets Per Transactions:{" "}
                                      {item.oc_max_no_of_ticket_per_transaction}
                                    </li>
                                  </>
                                )}
                                {item.offer_type_id == 2 && (
                                  <>
                                    <li className="b">
                                      Maximum discount per transaction:&nbsp;
                                      {item.oc_max_discount_amount}
                                    </li>
                                  </>
                                )}
                                {/* TODO OFFER TYPE 3 & 6 */}
                                {item.offer_type_id == 4 && (
                                  <>
                                    <li className="b">
                                      Minimum Ticket Price: &nbsp;
                                      {item.cco_min_ticket_amount}
                                    </li>
                                    <li className="b">
                                      Maximum discount per transaction: &nbsp;
                                      {item.cco_max_discount_amount}
                                    </li>
                                    <li className="b">
                                      Offer Price: &nbsp;{item.cco_offer_value}
                                      {item.cco_offer_value_is_percentage ===
                                      "Y"
                                        ? "%"
                                        : { curr_code }}
                                    </li>
                                    <li className="b">
                                      Special Instruction:
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item.cco_instruction,
                                        }}
                                      ></div>
                                    </li>
                                  </>
                                )}
                                {item.offer_type_id == 5 && (
                                  <>
                                    <li className="b">
                                      Bank Name: &nbsp;{item.bank_name}
                                    </li>
                                    <li className="b">
                                      Bank Card: &nbsp;{item.card_type}
                                    </li>
                                    {item.bo_weekly_redemption && (
                                      <li className="b">
                                        Weekly Redemption:
                                        {item.bo_weekly_redemption}
                                      </li>
                                    )}
                                    {item.bo_monthly_redemption && (
                                      <li className="b">
                                        Monthly Redemption:
                                        {item.bo_monthly_redemption}
                                      </li>
                                    )}
                                    {item.bo_yearly_redemption && (
                                      <li className="b">
                                        Yearly Redemption:
                                        {item.bo_yearly_redemption}
                                      </li>
                                    )}
                                    <li className="b">
                                      Offer Price: &nbsp;{item.bo_offer_value}
                                      {item.bo_offer_value_is_percentage === "Y"
                                        ? "%"
                                        : { curr_code }}
                                    </li>
                                    <li className="b">
                                      Special Instruction:
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item.bo_instruction,
                                        }}
                                      ></div>
                                    </li>
                                  </>
                                )}
                              </ul>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </>
                ))
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default withNamespaces()(PromotionsAndOffers);
