import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid page header-space">
      <div className="">
        <div className="title border mt-5">
          <h1>COMING SOON</h1>
        </div>
      </div>
    </div>
  );
};
export default withNamespaces()(FAQ);
