import React, { useEffect, useState, useRef, useContext } from "react";
import { withNamespaces } from "react-i18next";
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import cinemaBg from "@assets/pictures/cinema-banner.png";
import Slide1 from "@assets/pictures/how-1.jpg";
import Slide2 from "@assets/pictures/how-3.jpg";
import Slide3 from "@assets/pictures/sample-bg.png";

import hiwOne from "@assets/pictures/hiw-one.png";
import hiwTwo from "@assets/pictures/how-1.jpg";
import hiwThree from "@assets/pictures/hiw-three.png";
import hiwFour from "@assets/pictures/hiw-four.png";
import whiteArrowDown from "@assets/pictures/svgs/white-arrow-down.svg";
import map from "@assets/pictures/svgs/map.svg";
import Slider from "react-slick";
import AncilliaryHeroSection from "./partials/AncilliaryHeroSection.jsx";
import { motion } from "framer-motion";

const HowItWorks = ({ t }) => {
  // const history = useHistory();
  const heroSlider = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();

  const stack = [
    {
      title: t("how_works.0.title"),
      msg: t("how_works.0.para1"),
      msg1: t("how_works.0.para2"),
      imgSrc:
        "https://player.vimeo.com/video/517238877?title=0&byline=0&portrait=0",
    },
    {
      title: t("how_works.1.title"),
      msg: t("how_works.1.para1"),
      imgSrc: hiwOne,
    },
    {
      title: t("how_works.2.title"),
      msg: t("how_works.2.para1"),
      imgSrc:
        "https://player.vimeo.com/video/517241357?title=0&byline=0&portrait=0",
    },
    {
      title: t("how_works.3.title"),
      msg: t("how_works.3.para1"),
      imgSrc: hiwTwo,
    },
    {
      title: t("how_works.4.title"),
      msg: t("how_works.4.para1"),
      imgSrc:
        "https://player.vimeo.com/video/517238520?title=0&byline=0&portrait=0",
    },
  ];

  const heroSectionObj = [
    {
      backgroundImg: Slide1,
      title: t("how_works.landing-page.title1"),
      msg: t("how_works.landing-page.para1"),
    },
    {
      backgroundImg: Slide2,
      title: t("how_works.landing-page.title2"),
      msg: t("how_works.landing-page.para2"),
    },
    {
      backgroundImg: Slide3,
      title: t("how_works.landing-page.title3"),
      msg: t("how_works.landing-page.para3"),
    },
  ];
  return (
    <div>
      <div className="px-0 hiw-page">
        {/* Hero Section */}
        {/* <Slider {...heroSlider} className="">
          {[1, 2].map((banner, index) => (
            <section className="cinema-hero-section" key={index}>
              <div className="hero-img-section">
                <img src={cinemaBg} alt="Movie Banner" className="hero-img-1" />
              </div>
              <div className="hero-text justify-content-center">
                <div className="container-fluid container-xl">
                  <div className="row">
                    <div className="col-12 col-sm-8 col-lg-6 mx-auto text-center">
                      <p className="title">Faster, Better Booking</p>
                      <p className="msg">
                        Tell your friends, family, and everyone you know who'd
                        love to watch your favorite movie with you. We’re
                        determined to give every guest a great experience every
                        time they visit, and so continuously strive to innovate
                        and improve our hospitality and services.
                      </p>
                      <div className="d-flex justify-content-center">
                        <img src={whiteArrowDown} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))}
        </Slider> */}
        {/* <AncilliaryHeroSection sectionObj={heroSectionObj} /> */}
        <Slider {...heroSlider} className="">
          {heroSectionObj.map((banner, index) => (
            <div key={index}>
              <section className="cinema-hero-section">
                <div className="hero-img-section">
                  <img
                    src={banner.backgroundImg}
                    alt="Movie Banner"
                    className="hero-img-1"
                  />
                </div>
                <div className="hero-text justify-content-center">
                  <motion.div
                    className="container-fluid container-xl"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1.5 }}
                  >
                    <div className="row h-100 align-items-center">
                      <div className="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto text-center">
                        <p className="title">{banner.title}</p>
                        <p className="msg">{banner.msg}</p>
                        <div className="d-flex justify-content-center">
                          <img src={whiteArrowDown} className="down-arrow" />
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </section>
            </div>
          ))}
        </Slider>
        {/* Hero Section Ends */}

        <section className="container-fluid container-xl mt-3">
          <p className="big-heading text-center">{t("How it Works")}</p>
          {stack.map((stack, index) => (
            <div className="row presentation">
              <motion.div
                className={`col-12 col-sm-6 col-lg-5 mb-3 mb-sm-0 ${
                  index % 2 === 0 ? "order-sm-first" : "order-sm-last"
                }`}
                initial={{ x: -75, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.75 }}
              >
                <div className="d-flex flex-column justify-content-center align-items-start h-100">
                  <p className="title">{stack.title}</p>
                  <p className="msg">{stack.msg}</p>
                  {index === 0 && (
                    <button
                      className="btn-main btn-main-lg mt-3 mt-lg-4 mx-auto mx-md-0"
                      onClick={() => history.push("/")}
                    >
                      {t("Find movie")}
                    </button>
                  )}
                </div>
              </motion.div>
              <motion.div
                className="col-12 col-sm-6 col-lg-7 text-center"
                initial={{ x: 75, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.75 }}
              >
                {index % 2 === 0 ? (
                  <iframe
                    src={stack.imgSrc}
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                ) : (
                  <img className="img-fluid" src={stack.imgSrc} alt="" />
                )}
                {/* <img className="img-fluid" src={stack.imgSrc} alt="" /> */}
              </motion.div>
            </div>
          ))}

          <motion.button
            className="btn-main mx-auto col-sm-6 col-md-4 col-lg-3 px-0"
            onClick={() => history.push("/")}
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.75 }}
          >
            {t("Browse Movies")}
          </motion.button>
        </section>
      </div>
    </div>
  );
};
export default withNamespaces()(HowItWorks);
