export const FETCH_MOVIE_DETAILS = "FETCH_MOVIE_DETAILS";
export const FETCH_MOVIE_DETAILS_SUCCESS = "FETCH_MOVIE_DETAILS_SUCCESS";
export const FETCH_MOVIE_DETAILS_ERROR = "FETCH_MOVIE_DETAILS_ERROR";

export const FETCH_MOVIE_SCHEDULES = "FETCH_MOVIE_SCHEDULES";
export const FETCH_MOVIE_SCHEDULES_SUCCESS = "FETCH_MOVIE_SCHEDULES_SUCCESS";
export const FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS =
  "FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS";
export const FETCH_MOVIE_SCHEDULES_ERROR = "FETCH_MOVIE_SCHEDULES_ERROR";

export const FETCH_SEAT_LAYOUT = "FETCH_SEAT_LAYOUT";
export const FETCH_SEAT_LAYOUT_SUCCESS = "FETCH_SEAT_LAYOUT_SUCCESS";
export const FETCH_SEAT_LAYOUT_ERROR = "FETCH_SEAT_LAYOUT_ERROR";

export const FETCH_SEAT_TYPE = "FETCH_SEAT_TYPE";
export const FETCH_SEAT_TYPE_SUCCESS = "FETCH_SEAT_TYPE_SUCCESS";
export const FETCH_SEAT_TYPE_ERROR = "FETCH_SEAT_TYPE_ERROR";

export const FETCH_SCHEDULE_DETAILS = "FETCH_SCHEDULE_DETAILS";
export const FETCH_SCHEDULE_DETAILS_SUCCESS = "FETCH_SCHEDULE_DETAILS_SUCCESS";
export const FETCH_SCHEDULE_DETAILS_ERROR = "FETCH_SCHEDULE_DETAILS_ERROR";

export const FETCH_BOOKING_DETAILS = "FETCH_BOOKING_DETAILS";
export const FETCH_BOOKING_DETAILS_SUCCESS = "FETCH_BOOKING_DETAILS_SUCCESS";
export const FETCH_BOOKING_DETAILS_ERROR = "FETCH_BOOKING_DETAILS_ERROR";

export const SET_SEAT_SELECTED = "SET_SEAT_SELECTED";

export const CLEAR_SEAT_SELECTION = "CLEAR_SEAT_SELECTION";

export const ON_SET_COMPLETE_RATE_CARD = "ON_SET_COMPLETE_RATE_CARD";

export const ON_SET_COMPLETE_NEW_TOTAL_AMOUNT =
  "ON_SET_COMPLETE_NEW_TOTAL_AMOUNT";

export const RESERVE_SEAT = "RESERVE_SEAT";
export const RESERVE_SEAT_SUCCESS = "RESERVE_SEAT_SUCCESS";
export const RESERVE_SEAT_ERROR = "RESERVE_SEAT_ERROR";

export const BOOK_SEAT = "BOOK_SEAT";
export const BOOK_SEAT_SUCCESS = "BOOK_SEAT_SUCCESS";
export const BOOK_SEAT_ERROR = "BOOK_SEAT_ERROR";

export const SET_SHOW_BOOKING_ID = "SET_SHOW_BOOKING_ID";

export const SET_STAGE = "SET_STAGE";
export const SET_BOOKING_TYPE = "SET_BOOKING_TYPE";

export const SET_STRIPE_PUBLISHER_KEY = "SET_STRIPE_PUBLISHER_KEY";
export const STRIPE_PAYMENT_SUCCESS = "STRIPE_PAYMENT_SUCCESS";

export const FETCH_MINI_FESTIVALS = "FETCH_MINI_FESTIVALS";
export const FETCH_MINI_FESTIVALS_SUCCESS = "FETCH_MINI_FESTIVALS_SUCCESS";
export const FETCH_MINI_FESTIVALS_ERROR = "FETCH_MINI_FESTIVALS_ERROR";

export const FETCH_OTHER_SCREENINGS = "FETCH_OTHER_SCREENINGS";
export const FETCH_OTHER_SCREENINGS_SUCCESS = "FETCH_OTHER_SCREENINGS_SUCCESS";
export const FETCH_OTHER_SCREENINGS_ERROR = "FETCH_OTHER_SCREENINGS_ERROR";

export const FETCH_GLOBAL_OPTIONS = "FETCH_GLOBAL_OPTIONS";
export const FETCH_GLOBAL_OPTIONS_SUCCESS = "FETCH_GLOBAL_OPTIONS_SUCCESS";
export const FETCH_GLOBAL_OPTIONS_ERROR = "FETCH_GLOBAL_OPTIONS_ERROR";

export const FETCH_CURRENCY = "FETCH_CURRENCY";
export const FETCH_CURRENCY_SUCCESS = "FETCH_CURRENCY_SUCCESS";
export const FETCH_CURRENCY_ERROR = "FETCH_CURRENCY_ERROR";

export const FETCH_SCREENING_TYPES = "FETCH_SCREENING_TYPES";
export const FETCH_SCREENING_TYPES_SUCCESS = "FETCH_SCREENING_TYPES_SUCCESS";
export const FETCH_SCREENING_TYPES_ERROR = "FETCH_SCREENING_TYPES_ERROR";

export const SET_PRIVATE_SCREEN_AMOUNT = "SET_PRIVATE_SCREEN_AMOUNT";

export const START_TIMER = "START_TIMER";
export const RESET_TIMER = "RESET_TIMER";

export const RELEASE_SEAT = "RELEASE_SEAT";
export const RELEASE_SEAT_SUCCESS = "RELEASE_SEAT_SUCCESS";
export const RELEASE_SEAT_ERROR = "RELEASE_SEAT_ERROR";

export const SET_FROM_MOVIE_PAGE = "SET_FROM_MOVIE_PAGE";

export const CREATE_SHOW = "CREATE_SHOW";
export const CREATE_SHOW_SUCCESS = "CREATE_SHOW_SUCCESS";
export const CREATE_SHOW_ERROR = "CREATE_SHOW_ERROR";
export const SET_SHOW = "SET_SHOW";
export const SET_TIMEOUT_ERROR = "SET_TIMEOUT_ERROR";
export const SET_TIMER = "SET_TIMER";
export const CLEAR_STATE = "CLEAR_STATE";
export const FETCH_MOVIE_SCHEDULES_SUCCESS2 = "FETCH_MOVIE_SCHEDULES_SUCCESS2";

export const FETCH_RESERVATION_DETAILS = "FETCH_RESERVATION_DETAILS";
export const FETCH_RESERVATION_DETAILS_SUCCESS =
  "FETCH_RESERVATION_DETAILS_SUCCESS";
export const FETCH_RESERVATION_DETAILS_ERROR =
  "FETCH_RESERVATION_DETAILS_ERROR";

export const FETCH_MOVIE_SCHEDULES_SUCCESS3 = "FETCH_MOVIE_SCHEDULES_SUCCESS3";
export const STOP_TIMER = "STOP_TIMER";

export const SEND_SMS = "SEND_SMS";
export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS";
export const SEND_SMS_ERROR = "SEND_SMS_ERROR";

export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR";
export const SET_RESERVATION_ID = "SET_RESERVATION_ID";
export const SET_SELECTED_LANGUAGE = "SET_SELECTED_LANGUAGE";

export const FETCH_NOW_SHOWING = "FETCH_NOW_SHOWING";
export const FETCH_NOW_SHOWING_SUCCESS = "FETCH_NOW_SHOWING_SUCCESS";
export const FETCH_NOW_SHOWING_ERROR = "FETCH_NOW_SHOWING_ERROR";
export const SET_LANGUAGE_SPECIFIC_MOVIE_SCHEDULE_TODAY_DETAILS =
  "SET_LANGUAGE_SPECIFIC_MOVIE_SCHEDULE_TODAY_DETAILS";

export const FETCH_CINEMAS = "FETCH_CINEMAS";
export const FETCH_CINEMAS_SUCCESS = "FETCH_CINEMAS_SUCCESS";
export const FETCH_CINEMAS_ERROR = "FETCH_CINEMAS_ERROR";
export const SELECT_CINEMA_SUCCESS = "SELECT_CINEMA_SUCCESS";
export const SET_SELECTED_MINI_FESTIVAL = "SET_SELECTED_MINI_FESTIVAL";

export const SET_LANGUAGE_SPECIFIC_MOVIE_DETAILS =
  "SET_LANGUAGE_SPECIFIC_MOVIE_DETAILS";

export const FETCH_MOVIE_DETAILS_SUCCESS2 = "FETCH_MOVIE_DETAILS_SUCCESS2";
export const SET_IS_CREATE_YOUR_SHOW_ENABLED =
  "SET_IS_CREATE_YOUR_SHOW_ENABLED";

export const SET_MOVIE_FORMATS = "SET_MOVIE_FORMATS";
export const SET_MOVIE_LANGUAGES = "SET_MOVIE_LANGUAGES";
export const SET_TEMP_RESERVATION_ID = "SET_TEMP_RESERVATION_ID";
export const UPDATE_SEAT_LAYOUT = "UPDATE_SEAT_LAYOUT";

export const FETCH_ALL_MOVIES_SCHEDULES = "FETCH_ALL_MOVIES_SCHEDULES";
export const FETCH_ALL_MOVIES_SCHEDULES_SUCCESS =
  "FETCH_ALL_MOVIES_SCHEDULES_SUCCESS";
export const FETCH_ALL_MOVIES_SCHEDULES_ERROR =
  "FETCH_ALL_MOVIES_SCHEDULES_ERROR";
