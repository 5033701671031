import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { AnimatedSwitch, spring } from "react-router-transition";
// //import components
import moviesList from "@components/moviesList.js";
import miniFestivals from "@components/miniFestivals.jsx";
import SeatLayout from "@components/SeatLayout.jsx";
import createScreen from "@components/createScreen.js";
import confirmedShowList from "@components/confirmedShowList.js";
import mainHomeContainer from "@components/mainHomeContainer.jsx";
import MovieDetails from "@components/MovieDetails.jsx";
import CreateShowDetails from "@components/CreateShowDetails.jsx";
// import MovieDetail from "@components/movieDetails.js";
import bookingDetail from "@components/bookingDetail.js";
import confirmedBooking from "@components/confirmedBooking.js";
import checkout from "@components/checkout.jsx";
import Feedback from "@components/Feedback.jsx";
import paymentFlow from "@components/paymentFlow.jsx";
import finalTicket from "@components/FinalTicket.jsx";
import CinemaListing from "@components/CinemaListing.jsx";
import AuditoriumListing from "@components/AuditoriumListing.jsx";
import ScreeningType from "@components/ScreeningType.jsx";
import FAQs from "@components/FAQ.jsx";
import {} from "./";
import PromotionsAndOffers from "@components/PromotionsAndOffers.jsx";
import HowItWorks from "@components/HowItWorks.jsx";
import userProfile from "@components/user-profile-screens/Navigation.jsx";
import MobileMenu from "@components/MobileMenu.jsx";
import Fnb from "@components/Fnb.jsx";
import CinemaDetails from "@components/CinemaDetails.jsx";
import TermsAndConditions from "@components/TnC.jsx";
import PrivacyPolicy from "@components/PrivacyPolicy.jsx";
import Cookies from "@components/Cookies.jsx";
import Documentation from "@components/Documentation.jsx";
import Schedules from "@components/Schedules.jsx";
import i18n from "./plugins/i18n";
import AboutUs from "@components/AboutUs1.jsx";
import Page404 from "@components/Page404.jsx";
import { headerBg, titleCase, getLangISOCode } from "./helper/formatting";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchStripePublisherKey,
  fetchGlobalOptions,
  getCinemas,
  releaseRealTimeBlockedSeats,
} from "./store/movies/movieActions";
import CreateShowSeatLayout from "./components/CreateShowSeatLayout";
import BookEvent from "./components/BookEvent";

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}
// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}
// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};
const Router = () => {
  let globalOptions = useSelector((state) => state.movies.globalOptions);
  let location = useLocation();

  useEffect(() => {
    console.log("global options changed: ", globalOptions);
  }, [globalOptions]);

  useEffect(() => {
    const existingGuestUserId = localStorage.getItem(`guest_user_id`);
    if (!existingGuestUserId) {
      const guest_user_id = Math.floor(100000000 + Math.random() * 900000000);
      localStorage.setItem(`guest_user_id`, guest_user_id);
    }
  }, []);

  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );

  useEffect(() => {
    console.log("this use effect is triggered");
    let pathname = location.pathname;
    if (
      pathname &&
      !["seat-layout", "payment", "final-ticket", "fnb"].includes(
        pathname.split("/")?.[1]
      )
    ) {
      dispatch(releaseRealTimeBlockedSeats());
    }
  }, [location.pathname]);

  useEffect(() => {
    if (selected_language) {
      console.log(
        "🚀 ~ file: Router.js ~ line 106 ~ useEffect ~ selected_language",
        selected_language
      );
      let lang = selected_language.iso_2
        ? selected_language.iso_2.toLowerCase()
        : getLangISOCode(selected_language.lang_name);
      console.log("found lang = ", lang);
      i18n.changeLanguage(lang);
      moment.locale(lang.toLowerCase());
    }
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStripePublisherKey());
    dispatch(fetchGlobalOptions());
    dispatch(getCinemas());
  }, [dispatch]);

  return (
    //define path and use component
    <Switch>
      {/* <AnimatedSwitch
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={mapStyles}
            className="route-wrapper"> */}
      <Route exact path="/" component={mainHomeContainer} />
      <Route exact path="/movies-list" component={moviesList} />
      <Route
        exact
        path="/movie-details/:movie_id/:content_lang_id/:type?/:isPrivate?"
        component={MovieDetails}
      />
      <Route
        exact
        path="/create-show-details/:movie_id/:content_lang_id/:isPrivate?"
        component={CreateShowDetails}
      />
      {/*<Route exact path="/movie-details" component={MovieDetail} />*/}
      <Route
        exact
        path="/seat-layout/:ss_id/:screen_id/:md_id"
        component={SeatLayout}
      />
      <Route
        exact
        path="/create-show-seat-layout/:mycinema_ss_d_id/:booking_type_id"
        component={CreateShowSeatLayout}
      />
      <Route exact path="/create-screen" component={createScreen} />
      <Route exact path="/confirmed-show-list" component={confirmedShowList} />
      <Route exact path="/booking-details" component={bookingDetail} />
      <Route
        exact
        path="/screening-type/:mycinema_ss_d_id"
        component={ScreeningType}
      />
      <Route exact path="/checkout" component={checkout} />
      <Route exact path="/feedback" component={Feedback} />
      <Route exact path="/confirmed-booking" component={confirmedBooking} />
      <Route exact path="/mini-festivals" component={miniFestivals} />
      <Route
        exact
        path="/payment/:reservation_id/:category_id"
        // /:category_id/:movie_id/:content_lang_id/:genre_id/:isPrivate/
        component={paymentFlow}
      />
      <Route
        exact
        path="/final-ticket/:show_booking_id/:category_id"
        component={finalTicket}
      />
      <Route exact path="/cinema-listing" component={CinemaListing} />
      <Route exact path="/auditorium-listing" component={AuditoriumListing} />
      <Route exact path="/faqs" component={FAQs} />
      <Route
        exact
        path="/promotions-and-offers"
        component={PromotionsAndOffers}
      />
      <Route exact path="/how-it-works" component={HowItWorks} />
      <Route exact path="/user-profile" component={userProfile} />
      <Route exact path="/menu" component={MobileMenu} />
      {/* <Route exact path="/fnb" component={Fnb} /> */}
      <Route exact path="/fnb/:ss_id/:screen_id/:md_id" component={Fnb} />
      <Route
        exact
        path="/fnb/:mycinema_ss_d_id/:booking_type_id"
        component={Fnb}
      />
      <Route
        exact
        path="/cinema-details/:cinema_id"
        component={CinemaDetails}
      />
      <Route exact path="/tnc" component={TermsAndConditions} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/documentation" component={Documentation} />
      <Route exact path="/cookies" component={Cookies} />
      <Route exact path="/book-event" component={BookEvent} />
      <Route exact path="/schedules" component={Schedules} />

      <Route path="*" component={Page404} />

      {/* </AnimatedSwitch> */}
    </Switch>
  );
};
export default Router;
