import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import GenericService from "@apiService/GenericService";

const AboutUs = ({ t }) => {
  const [pageContent, setPageContent] = useState(null);
  const aboutUs = useRef();

  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { iso_2, lang_id, lang_name } = selected_language;

  async function mspage(pageId) {
    const { data } = await GenericService.GetMsPage(pageId);
    try {
      if (data.status && data.Records) {
        aboutUs.current.innerHTML =
          data.Records.filter((langObj) => {
            return langObj.iso_2.toLowerCase() === iso_2.toLowerCase();
          })[0]?.page_content || t("Coming Soon");
      } else {
        console.log("message", data.message);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    mspage(1);
  }, [iso_2]);

  return (
    <div className="container-fluid ms-page header-space" ref={aboutUs}>
      {/* <div className="">
        <div className="title">
          <h3>{t("about-us")}</h3>
        </div>
        <div className="content">
          <p>{t("about-us-msg.para1")}</p>
          <p>{t("about-us-msg.para2")}</p>
          <p>{t("about-us-msg.para3")}</p>
          <p>{t("about-us-msg.para4")}</p>
        </div>
      </div> */}
    </div>
  );
};
export default withNamespaces()(AboutUs);
