import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFnbItems,
  onSelectFnb,
  clearFnbState,
  saveFnbItems,
  fetchFnbCategories,
} from "@store/foodAndBeverages/foodAndBeveragesActions";
import {
  fnbItemsWithSearchSelector,
  fnbItemCategoriesSelector,
} from "@store/foodAndBeverages/foodAndBeveragesSelectors";
import popcorn from "@assets/pictures/popcorn.png";
import fnbSample from "@assets/pictures/fnb-sample.jpg";
import { useParams } from "react-router";
import Spinner from "./partials/Spinner";
import arrowBack from "@assets/pictures/svgs/arrow-back.svg";
import arrowBackActive from "@assets/pictures/svgs/arrow-back-active.svg";
import dropDownArrow from "@assets/pictures/svgs/dropdown-arrow.svg";
import { motion } from "framer-motion";
import useIsMobile from "../customHooks/useIsMobile";
import searchIcon from "@assets/pictures/svgs/search.svg";
import { currencyFormatter } from "@helper/currencyFormatter";
import { getLangSpecificAttribute } from "@helper/languages";

const Fnb = ({ t }) => {
  const {
    ss_id,
    screen_id,
    md_id,
    mycinema_ss_d_id,
    booking_type_id,
  } = useParams();
  let category_id = ss_id && screen_id && md_id ? 2 : 1;
  const [back, setBack] = useState(false);
  const [itemType, setItemType] = useState("all");
  const isMobile = useIsMobile();
  const [footerAccordion, setFooterAccordion] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const history = useHistory();

  const dispatch = useDispatch();
  const reservation_id = useSelector((state) => state.movies.reservation_id);
  // const fnbItems = useSelector((state) => state.fnb.fnb_items);
  const fnbItemsLoader = useSelector((state) => state.fnb.fnb_loader);

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  // const fnbCategories = useSelector((state) =>
  //   fnbItemCategoriesSelector(state)
  // );
  const fnbCategories = useSelector((state) => state.fnb.fnb_categories);
  const fnbItemsWithSearch = useSelector((state) =>
    fnbItemsWithSearchSelector(state, search, selectedCategory)
  );

  const selectedFnbItems = useSelector((state) => state.fnb.selected_fnb_items);
  const fnbPrice = useSelector((state) => state.fnb.fnb_price);

  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);

  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );

  const { lang_name, iso_2, lang_id } = selected_language;

  useEffect(() => {
    if (scheduleDetails) {
      dispatch(
        fetchFnbItems({ cinema_id: scheduleDetails.cinema_id, reservation_id })
      );
      dispatch(fetchFnbCategories());
    }
  }, [scheduleDetails]);

  function footerAccordionHandler() {
    console.log(footerAccordion);
    if (window.innerWidth < 768) {
      setFooterAccordion(true);
    } else if (window.innerWidth >= 768) {
      setFooterAccordion(false);
    }
  }

  window.addEventListener("resize", footerAccordionHandler);
  useEffect(footerAccordionHandler, []);

  const goToSeatLayout = useCallback(() => {
    if (mycinema_ss_d_id && booking_type_id) {
      history.replace(
        `/create-show-seat-layout/${mycinema_ss_d_id}/${booking_type_id}`
      );
    } else if (ss_id && screen_id && md_id) {
      history.replace(`/seat-layout/${ss_id}/${screen_id}/${md_id}`);
    }
  }, [mycinema_ss_d_id, booking_type_id, ss_id, screen_id, md_id]);

  const proceedToPayment = () => {
    // perform reservation here
    if (selectedFnbItems?.length === 0) {
      history.push(`/payment/${reservation_id}/${category_id}`);
    } else {
      dispatch(saveFnbItems(category_id)).then((status) => {
        if (status) {
          history.push(`/payment/${reservation_id}/${category_id}`);
        }
      });
    }
  };

  if (!reservation_id) {
    goToSeatLayout();
  }

  return (
    <div>
      <div className="container-fluid container-xl header-space">
        <p className="mx-auto big-heading text-md-center for-prev-step">
          <div className="prev-step">
            <button
              className="btn-main mx-auto mt-2"
              onMouseOver={() => setBack(true)}
              onMouseOut={() => setBack(false)}
              onClick={goToSeatLayout}
            >
              <img
                src={back ? arrowBackActive : arrowBack}
                className="mr3"
                alt=""
              />
              {/* Back */}
            </button>
          </div>
          {t("Concessions")}
        </p>

        {/* <section className="row justify-content-center for-prev-step">
          <div className="prev-step">
            <button
              className="btn-main mx-auto mt-3 mt-xl-4"
              onMouseOver={() => setBack(true)}
              onMouseOut={() => setBack(false)}
            >
              <img
                src={back ? arrowBackActive : arrowBack}
                className="mr3"
                alt=""
              /> */}
        {/* Back */}
        {/* </button>
          </div> */}

        {/* <div className="col-sm-9 mx-auto">
            <div className="steps">
              <div className={`step ticket complete`}></div>
              <div className={`step screen complete`}></div>
              <div className={`step seat complete`}></div>
            </div>
          </div> */}
        {/* </div> */}
        {/* </section> */}

        <section className="row mb-3">
          <article className="col-10 col-md-6 col-lg-5 col-xl-3">
            <label className="fnb-search">
              <img
                src={searchIcon}
                alt="search"
                onClick={(e) => e.target.nextElementSibling.focus()}
              />
              <input
                type="search"
                placeholder={t("Search for concessions")}
                autoFocus
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </label>
          </article>
        </section>

        <section className="row">
          <article className="col-12">
            {/* <section className="row align-items-center">
              <article className="col-lg-6 order-1 order-md-0"> */}
            <div className="fnb-categories">
              <button
                className={`fnb-category-btn ${
                  selectedCategory === "" ? "selected" : ""
                }`}
                onClick={() => setSelectedCategory("")}
              >
                {t("All")}
              </button>
              {fnbCategories &&
                fnbCategories.length > 0 &&
                fnbCategories.map((category, categoryIndex) => (
                  <button
                    key={categoryIndex}
                    className={`fnb-category-btn ${
                      selectedCategory === category ? "selected" : ""
                    }`}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {getLangSpecificAttribute(
                      category?.FnbCategoryContent,
                      lang_id,
                      "category_lang_name"
                    ) || category.fc_category}
                  </button>
                ))}
            </div>
            {/* </article> */}
            {/* <article className="col-lg-6 mb-3 mb-md-0">
                <label className="fnb-search">
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => e.target.nextElementSibling.focus()}
                  />
                  <input
                    type="search"
                    placeholder="Search for concessions"
                    autoFocus
                  />
                </label>
              </article> */}
            {/* </section> */}
          </article>
        </section>

        <section className="row fnb-row">
          <article className="col-md-5 col-xl-3 fnb-mobile-bar">
            {!footerAccordion && (
              <>
                <section
                  className={`fnb-order-details ${isMobile ? "mb-2" : "mb-3"}`}
                >
                  <p className="title">{t("Your order details")}</p>
                  {selectedFnbItems &&
                    selectedFnbItems.length > 0 &&
                    selectedFnbItems.map((selectedItem) => (
                      <article
                        className="fnb-item-details"
                        key={selectedItem.item_id}
                      >
                        <p>
                          {getLangSpecificAttribute(
                            selectedItem?.FnbContent,
                            lang_id,
                            "item_lang_name"
                          ) || selectedItem.item_name}
                          {selectedItem.Quantity > 1 && (
                            <>
                              &nbsp;
                              <span>x{selectedItem.Quantity}</span>
                              <p>
                                {t("Single Item")} - {curr_code}{" "}
                                {currencyFormatter(
                                  selectedItem.fp_total_amount
                                )}
                              </p>
                            </>
                          )}
                        </p>
                        <p>
                          {curr_code}{" "}
                          {currencyFormatter(selectedItem.total_price)}
                        </p>
                      </article>
                    ))}
                  {selectedFnbItems.length === 0 && (
                    <section className="d-flex justify-content-center align-items-center">
                      {t("No Items Selected")}
                    </section>
                  )}
                  <article className="fnb-total">
                    <p>{t("Total")}</p>
                    <p>
                      {curr_code} {currencyFormatter(fnbPrice.total_price)}
                    </p>
                  </article>
                </section>
                {!isMobile && (
                  <button
                    className="btn-main mx-auto"
                    onClick={() => {
                      proceedToPayment();
                    }}
                  >
                    {selectedFnbItems.length > 0 ? t("Confirm") : t("Skip")}
                  </button>
                )}
              </>
            )}
            {isMobile && (
              <article className="row align-items-center">
                <div className="col-6">
                  <p className="fnb-item-count">
                    {selectedFnbItems.length} {t("Items selected")}
                  </p>
                  <p className="">
                    {curr_code} {currencyFormatter(fnbPrice.total_price)}
                  </p>
                </div>
                <div className="col-6 for-ft">
                  <button
                    className="btn-main footer"
                    onClick={() => {
                      proceedToPayment();
                    }}
                  >
                    {selectedFnbItems.length > 0 ? t("Confirm") : t("Skip")}
                  </button>
                  <div className="footer-toggle">
                    <figure
                      className={`${!footerAccordion ? "ft-rotate" : ""}`}
                      onClick={() => {
                        footerAccordion
                          ? setFooterAccordion(false)
                          : setFooterAccordion(true);
                      }}
                    >
                      <img src={dropDownArrow} alt="" />
                    </figure>
                  </div>
                </div>
              </article>
            )}
          </article>
          <article
            className={`col-md-7 col-xl-9 ${
              false && !isMobile ? "fnb-items" : ""
            }`}
          >
            {fnbItemsLoader && (
              <div className="row d-flex align-items-center justify-content-center">
                <Spinner />
              </div>
            )}
            {!fnbItemsLoader &&
              (fnbItemsWithSearch && fnbItemsWithSearch.length > 0 ? (
                <div className="row">
                  {fnbItemsWithSearch.map((item) => (
                    <FnbCard item={item} />
                  ))}
                </div>
              ) : (
                <div className="row justify-content-center align-items-center">
                  {t("No Items Found")}
                </div>
              ))}
          </article>
        </section>
      </div>
    </div>
  );
};

function FnbCard({ item }) {
  const [index, setIndex] = useState(0);

  const dispatch = useDispatch();

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  const onSelectFnbItem = (type, item) => {
    dispatch(onSelectFnb(type, item));
  };

  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );

  const { lang_name, iso_2, lang_id } = selected_language;

  return (
    <motion.div
      className="col-6 col-xl-4"
      initial={{ x: -75, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.75 }}
    >
      <div className="fnb-card">
        <figure className="img-wrapper">
          <img src={item.item_image_url} alt={item.item_name} />
        </figure>
        <article className="">
          <div className="fnb-content-row">
            <p className="">
              {getLangSpecificAttribute(
                item?.FnbContent,
                lang_id,
                "item_lang_name"
              ) || item.item_name}
            </p>
            <p className="">
              {curr_code} {currencyFormatter(item.fp_total_amount)}
            </p>
          </div>
          <div className="fnb-content-row">
            {/* <aside>
              <button className="btn-main">Add</button>
            </aside> */}
            <aside className="modifier-btn">
              <span
                className={!item.Quantity ? "inactive" : ""}
                onClick={() => onSelectFnbItem("sub", item)}
              >
                -
              </span>
              <span className={!item.Quantity ? "inactive" : ""}>
                {item.Quantity !== 0 && item.Quantity ? item.Quantity : "0"}
              </span>
              <span className="" onClick={() => onSelectFnbItem("add", item)}>
                +
              </span>
            </aside>
          </div>
        </article>
        {/* <aside className="">
          <p>25% off</p>
          <p>CHF 48</p>
        </aside> */}
      </div>
    </motion.div>
  );
}

export default withNamespaces()(Fnb);
