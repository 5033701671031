import {
  FETCH_MOVIE_DETAILS,
  FETCH_MOVIE_DETAILS_SUCCESS,
  FETCH_MOVIE_DETAILS_ERROR,
  FETCH_MOVIE_SCHEDULES,
  FETCH_MOVIE_SCHEDULES_SUCCESS,
  FETCH_MOVIE_SCHEDULES_ERROR,
  FETCH_SEAT_LAYOUT,
  FETCH_SEAT_LAYOUT_SUCCESS,
  FETCH_SEAT_LAYOUT_ERROR,
  FETCH_SEAT_TYPE,
  FETCH_SEAT_TYPE_SUCCESS,
  FETCH_SEAT_TYPE_ERROR,
  SET_SEAT_SELECTED,
  CLEAR_SEAT_SELECTION,
  ON_SET_COMPLETE_RATE_CARD,
  ON_SET_COMPLETE_NEW_TOTAL_AMOUNT,
  FETCH_SCHEDULE_DETAILS,
  FETCH_SCHEDULE_DETAILS_SUCCESS,
  FETCH_SCHEDULE_DETAILS_ERROR,
  RESERVE_SEAT,
  RESERVE_SEAT_SUCCESS,
  RESERVE_SEAT_ERROR,
  FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
  SET_STAGE,
  SET_BOOKING_TYPE,
  FETCH_BOOKING_DETAILS,
  FETCH_BOOKING_DETAILS_SUCCESS,
  SET_SHOW_BOOKING_ID,
  FETCH_BOOKING_DETAILS_ERROR,
  BOOK_SEAT_SUCCESS,
  SET_STRIPE_PUBLISHER_KEY,
  FETCH_MINI_FESTIVALS,
  FETCH_MINI_FESTIVALS_SUCCESS,
  FETCH_MINI_FESTIVALS_ERROR,
  FETCH_OTHER_SCREENINGS,
  FETCH_OTHER_SCREENINGS_SUCCESS,
  FETCH_OTHER_SCREENINGS_ERROR,
  FETCH_GLOBAL_OPTIONS,
  FETCH_GLOBAL_OPTIONS_SUCCESS,
  FETCH_GLOBAL_OPTIONS_ERROR,
  FETCH_CURRENCY,
  FETCH_CURRENCY_SUCCESS,
  FETCH_CURRENCY_ERROR,
  FETCH_SCREENING_TYPES,
  FETCH_SCREENING_TYPES_SUCCESS,
  FETCH_SCREENING_TYPES_ERROR,
  SET_PRIVATE_SCREEN_AMOUNT,
  START_TIMER,
  RESET_TIMER,
  RELEASE_SEAT,
  RELEASE_SEAT_SUCCESS,
  RELEASE_SEAT_ERROR,
  FETCH_MOVIE_DETAILS_SUCCESS2,
  SET_FROM_MOVIE_PAGE,
  CREATE_SHOW,
  CREATE_SHOW_SUCCESS,
  CREATE_SHOW_ERROR,
  SET_SHOW,
  SET_TIMEOUT_ERROR,
  FETCH_RESERVATION_DETAILS,
  FETCH_RESERVATION_DETAILS_SUCCESS,
  FETCH_RESERVATION_DETAILS_ERROR,
  SET_TIMER,
  CLEAR_STATE,
  FETCH_MOVIE_SCHEDULES_SUCCESS2,
  FETCH_MOVIE_SCHEDULES_SUCCESS3,
  STOP_TIMER,
  SET_RESERVATION_ID,
  BOOK_SEAT_ERROR,
  SET_SELECTED_LANGUAGE,
  FETCH_NOW_SHOWING,
  FETCH_NOW_SHOWING_SUCCESS,
  FETCH_NOW_SHOWING_ERROR,
  FETCH_CINEMAS,
  FETCH_CINEMAS_SUCCESS,
  FETCH_CINEMAS_ERROR,
  SELECT_CINEMA_SUCCESS,
  SET_LANGUAGE_SPECIFIC_MOVIE_DETAILS,
  SET_SELECTED_MINI_FESTIVAL,
  SEND_SMS_ERROR,
  SEND_EMAIL_ERROR,
  SET_IS_CREATE_YOUR_SHOW_ENABLED,
  SET_MOVIE_FORMATS,
  SET_MOVIE_LANGUAGES,
  SET_TEMP_RESERVATION_ID,
  UPDATE_SEAT_LAYOUT,
  FETCH_ALL_MOVIES_SCHEDULES,
  FETCH_ALL_MOVIES_SCHEDULES_SUCCESS,
  FETCH_ALL_MOVIES_SCHEDULES_ERROR,
} from "./movieTypes";
import { groupBy } from "lodash";
import { getValueFromGlobalOptions } from "@helper/utils";

let INITIAL_MINS = 4;
let INITIAL_SECONDS = 59;

let initialState = {
  nowShowing: null,
  nowShowingLoading: false,
  nowShowingError: null,

  movieDetails: [],
  movieDetailsLoading: false,
  movieDetailsError: null,

  movieSchedules: null,
  movieScheduleDetails: null,
  movieSchedulesLoading: false,
  movieSchedulesError: null,
  movieFormats: [],
  movieLanguages: [],
  seatLayout: null,
  seatLayoutLoading: false,
  seatLayoutError: null,
  seatType: null,
  seatTypeLoading: false,
  seatTypeError: null,
  seats_selected: [],
  isCompleteRateCard: false,
  seatGroupAll: null,
  ft_id: null,
  totalAmount: null,
  netAmount: null,
  selectedTicketPrice: {
    priceInCents: 0,
    taxInCents: 0,
    totalWithFnb: 0,
    taxWithFnb: 0,
  },
  scheduleDetails: null,
  scheduleDetailsLoading: false,
  scheduleDetailsError: null,
  reservation_id: null,
  reservation_idLoading: false,
  reservation_idError: null,
  stage: 1,
  show: null,
  booking_type: null,
  bookingDetails: null,
  bookingDetailsLoading: false,
  bookingDetailsError: null,
  showBookingID: null,
  bookingSuccessful: false,
  stripePublisherKey: null,
  miniFestivals: null,
  miniFestivalsLoading: false,
  miniFestivalsError: null,
  otherScreenings: [],
  otherScreeningsLoading: false,
  otherScreeningsError: null,
  currency: {},
  loading: false,
  error: null,
  globalSettings: {},
  globalOptions: [],
  screeningTypes: [],
  screeningTypesLoading: false,
  screeningTypesError: null,
  timer: {
    minutes: INITIAL_MINS,
    seconds: INITIAL_SECONDS,
  },
  timerCompleted: false,
  fromMoviePage: false,
  myCinemaCreateShow: {},
  timeoutError: false,
  reservationDetails: null,
  dateMovieScheduleDetails: null,
  filterMovieScheduleDetails: null,
  bookSeatsError: null,
  selectedCinema: JSON.parse(localStorage.getItem(`selected_cinema`)) || null,
  cinemas: [],
  getLanguageSpecificMovieDetails: null,
  getLanguageSpecificGenres: null,
  getLanguageSpecificLanguages: null,
  selectedMiniFestival: null,
  resendSMSError: null,
  resendEmailError: null,
  movieDetailsCopy: null,
  selected_language: JSON.parse(localStorage.getItem("selected_language")) || {
    lang_id: 1,
    lang_name: "English",
    alternate_lang_name: null,
    lang_is_active: "Y",
    created_by: null,
    created_at: null,
    updated_by: 1,
    updated_at: "2021-03-09T11:05:00.000Z",
    iso_2: "en",
    iso_3: "en",
  },
  createYourShowEnabled: false,
  reservation_id_temp: null,
  allMoviesSchedulesLoading: false,
  allMoviesSchedules: {
    movieSchedules: [],
    datesArray: [],
  },
  allMoviesSchedulesError: null,
};

const movieReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SEND_SMS_ERROR:
      return { ...state, resendSMSError: payload };
    case SEND_EMAIL_ERROR:
      return { ...state, resendEmailError: payload };
    case SET_SELECTED_MINI_FESTIVAL:
      return {
        ...state,
        selectedMiniFestival: payload,
      };
    case SET_LANGUAGE_SPECIFIC_MOVIE_DETAILS:
      console.log(state, `manav 1234`);
      const {
        selected_language: { lang_name },
        movieDetails,
      } = state;
      let getLanguageSpecificMovieDetails;

      switch (lang_name) {
        case "English":
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === "English"
          );

          break;
        case "German":
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === "German"
          );
          break;
        case "Italian":
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === "Italian"
          );
          break;
        case "French":
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === "French"
          );
          break;
        case "Hindi":
          getLanguageSpecificMovieDetails = movieDetails?.altMovieContent?.find(
            (c) => c.lang_name === "Hindi"
          );
          break;
        default:
          getLanguageSpecificMovieDetails = movieDetails;
      }
      if (getLanguageSpecificMovieDetails === undefined) {
        getLanguageSpecificMovieDetails = movieDetails;
      }
      return { ...state, getLanguageSpecificMovieDetails };

    case FETCH_CINEMAS:
      return {
        ...state,
        cinemas: [],
      };
    case FETCH_CINEMAS_SUCCESS:
      return {
        ...state,
        cinemas: payload,
      };
    case FETCH_CINEMAS_ERROR:
      return {
        cinemas: [],
      };
    case SELECT_CINEMA_SUCCESS:
      localStorage.setItem(`selected_cinema`, JSON.stringify(payload));
      return { ...state, selectedCinema: payload };
    case SET_SELECTED_LANGUAGE:
      localStorage.setItem(`selected_language`, JSON.stringify(payload));
      return {
        ...state,
        selected_language: payload,
      };
    case BOOK_SEAT_ERROR:
      return { ...state, bookSeatsError: payload };
    case BOOK_SEAT_SUCCESS:
      return {
        ...state,
        bookingSuccessful: true,
      };
    case FETCH_MOVIE_SCHEDULES_SUCCESS3:
      return { ...state, filterMovieScheduleDetails: payload };
    case FETCH_MOVIE_SCHEDULES_SUCCESS2:
      return {
        ...state,
        dateMovieScheduleDetails: payload,
      };
    case CLEAR_STATE:
      let currency = state.currency;
      return {
        ...initialState,
        currency,
      };
    case SET_TIMEOUT_ERROR:
      return { ...state, timeoutError: payload };
    case SET_SHOW:
      return { ...state, show: payload };
    case FETCH_RESERVATION_DETAILS:
    case FETCH_RESERVATION_DETAILS_ERROR:
      return {
        ...state,
        reservationDetails: null,
      };
    case FETCH_RESERVATION_DETAILS_SUCCESS:
      return {
        ...state,
        reservationDetails: payload,
      };
    case CREATE_SHOW:
    case CREATE_SHOW_ERROR:
      return {
        ...state,
        myCinemaCreateShow: {},
      };
    case CREATE_SHOW_SUCCESS:
      return {
        ...state,
        myCinemaCreateShow: payload,
      };
    case FETCH_NOW_SHOWING:
      return {
        ...state,
        nowShowing: null,
        nowShowingLoading: true,
      };
    case FETCH_NOW_SHOWING_SUCCESS:
      console.log("payload manav :>> ", payload);
      return {
        ...state,
        nowShowing:
          state.nowShowing === null
            ? payload
            : {
                ...state.nowShowing,
                data: [...state.nowShowing.data, ...payload.data],
              },
        nowShowingLoading: false,
      };
    case FETCH_NOW_SHOWING_ERROR:
      return {
        ...state,
        nowShowing: null,
        nowShowingError: payload,
      };
    case SET_PRIVATE_SCREEN_AMOUNT:
      return {
        ...state,
        totalAmount: payload.amount,
        netAmount: payload.net_amount,
      };
    case FETCH_MOVIE_DETAILS:
      return {
        ...state,
        movieDetails: null,
        movieDetailsLoading: true,
      };
    case FETCH_MOVIE_DETAILS_SUCCESS2:
      return {
        ...state,
        movieDetailsCopy: payload,
      };
    case FETCH_MOVIE_DETAILS_SUCCESS:
      return {
        ...state,
        movieDetails: payload,
        movieDetailsLoading: false,
      };
    case FETCH_MOVIE_DETAILS_ERROR:
      return { ...state, movieDetails: null, movieDetailsError: payload };

    case FETCH_MOVIE_SCHEDULES:
      return {
        ...state,
        movieSchedules: null,
        movieSchedulesLoading: true,
      };
    case FETCH_MOVIE_SCHEDULES_SUCCESS:
      return {
        ...state,
        movieSchedules: payload,
        movieSchedulesLoading: false,
      };
    case FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS:
      return {
        ...state,
        movieScheduleDetails: payload,
      };
    case FETCH_MOVIE_SCHEDULES_ERROR:
      return { ...state, movieSchedules: null, movieSchedulesError: payload };
    case FETCH_SCHEDULE_DETAILS:
      return {
        ...state,
        scheduleDetails: null,
        scheduleDetailsLoading: true,
      };
    case FETCH_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        scheduleDetails: payload,
        scheduleDetailsLoading: false,
      };
    case FETCH_SCHEDULE_DETAILS_ERROR:
      return { ...state, scheduleDetails: null, scheduleDetailsError: payload };
    case RESERVE_SEAT:
      return {
        ...state,
        reservation_id: null,
        reservation_idLoading: true,
      };
    case RESERVE_SEAT_SUCCESS:
    case SET_RESERVATION_ID:
      return {
        ...state,
        reservation_id: payload,
        reservation_idLoading: false,
      };
    case RELEASE_SEAT_SUCCESS:
      return {
        ...state,
        reservation_id: null,
      };
    case RESERVE_SEAT_ERROR:
      return {
        ...state,
        reservation_id: null,
        reservation_idError: payload,
      };
    case FETCH_SEAT_LAYOUT:
      return {
        ...state,
        seatLayout: [],
        seatLayoutLoading: true,
      };
    case FETCH_SEAT_LAYOUT_SUCCESS: {
      // Seat layout sorting start
      let seatData = [];
      let seatData1 = groupBy(payload.seats.Records, "sst_seat_type");
      payload.seatType.Records = payload.seatType.Records.sort(
        (a, b) => a.sst_order - b.sst_order
      );
      // console.log('seatData1 :', seatData1);
      payload.seatType.Records.map((type) => {
        for (var key in seatData1) {
          if (seatData1.hasOwnProperty(key)) {
            // console.log(key + " -> " + seatData1[key]);
            if (key == type.sst_seat_type) {
              seatData[key] = seatData1[key];
            }
          }
        }
        // console.log('seatData :', seatData);
      });
      let tempArray = [];
      for (let key in seatData) {
        if (seatData.hasOwnProperty(key)) {
          let seat_price = 0;
          let seat_price_obj = seatData[key].filter((x) => {
            return x;
          });
          // console.log('seat_price_obj :', seat_price_obj);
          if (seat_price_obj) {
            seat_price = seat_price_obj[0].seat_price;
          }
          // console.log('seat_price :', seat_price);
          tempArray.push({
            seatType: key,
            seat_price: seat_price,
            seats: seatData[key],
          });
        }
      }
      // let finalTempArray = [];
      // tempArray.map(item=>{
      //   let tempArray1 = [];
      //   let newtempArray =groupBy(item,"")
      // })
      let finalArray = [];

      // [{
      //   seatType: 'gold',
      //   seats: []
      // }]

      for (let i = 0; i < tempArray.length; i++) {
        let singleSeatType = tempArray[i];

        let seatData_1 = groupBy(singleSeatType.seats, "sl_row_num");
        // seatData_1 = {
        //   0: [],
        //   1: []
        // }
        let tempArray_1 = [];
        for (let key in seatData_1) {
          if (seatData_1.hasOwnProperty(key)) {
            let seatData_2 = seatData_1[key].map((singleSeat) => {
              singleSeat.selectStatus = false;
              singleSeat.SelectStatusFlag = false;
              return singleSeat;
            });
            seatData_2 = seatData_2.sort(function (a, b) {
              return a.sl_col_num - b.sl_col_num;
            });

            tempArray_1.push(seatData_2);
          }
        }
        // console.log('tempArray_1 before', tempArray_1)

        // console.log('tempArray_1 after', tempArray_1)
        // tempArray_1 = [[], []]
        tempArray[i].seatsFinal = tempArray_1;
        // finalArray.push({ seatType: tempArray, seats: tempArray_1 })
      }
      // if (state.totalNumberOfSeats == 0) {
      //     vmVue.$root.$emit('bv::show::modal', 'seat_select_modal');
      // }
      // state.seatLayoutData = tempArray;
      // Seat layout sorting end
      return {
        ...state,
        seatLayout: tempArray,
        seatLayoutLoading: false,
      };
    }
    case UPDATE_SEAT_LAYOUT: {
      let newSeatLayout = [...state.seatLayout];
      let seats_selected = state.seats_selected;
      let { sl_id, status } = action.payload;
      let globalOptions = state.globalOptions;
      let isCovidSeatEnabled = getValueFromGlobalOptions(
        globalOptions,
        "ENABLE_COVID_SEAT_LAYOUT"
      );
      console.log("inside update seat layout reducer =>> ", action.payload);

      for (let seatType of newSeatLayout) {
        for (let [rowIndex, row] of seatType.seatsFinal.entries()) {
          for (let [seatIndex, seat] of row.entries()) {
            if (seat.sl_id == sl_id) {
              seat.seat_reserve_type_id = status;
              seat.covidBlockedTemp = false;

              if (isCovidSeatEnabled === "Y") {
                if (seatType.seatsFinal[rowIndex][seatIndex - 1]) {
                  let leftSeat = seatType.seatsFinal[rowIndex][seatIndex - 1];
                  let isNotSelected =
                    seats_selected.findIndex(
                      (x) => x.sl_id == leftSeat.sl_id
                    ) === -1;
                  if (
                    ![8, 2, 13].includes(leftSeat.seat_reserve_type_id) &&
                    isNotSelected
                  ) {
                    leftSeat.covidBlockedTemp =
                      status === 8
                        ? true
                        : seatType.seatsFinal[rowIndex][seatIndex - 2]
                            ?.seat_reserve_type_id === 8
                        ? true
                        : false;
                  } else if (leftSeat.seat_reserve_type_id == 8) {
                    leftSeat.covidBlockedTemp = false;
                    seat.covidBlockedTemp = status === 1 ? true : false;
                  }
                }

                if (seatType.seatsFinal[rowIndex][seatIndex + 1]) {
                  let rightSeat = seatType.seatsFinal[rowIndex][seatIndex + 1];
                  if (
                    ![8, 2, 13].includes(rightSeat.seat_reserve_type_id) &&
                    seats_selected.findIndex(
                      (x) => x.sl_id == rightSeat.sl_id
                    ) === -1
                  ) {
                    rightSeat.covidBlockedTemp =
                      status === 8
                        ? true
                        : seatType.seatsFinal[rowIndex][seatIndex + 2]
                            ?.seat_reserve_type_id === 8
                        ? true
                        : false;
                  } else if (rightSeat.seat_reserve_type_id == 8) {
                    rightSeat.covidBlockedTemp = false;
                    seat.covidBlockedTemp = status === 1 ? true : false;
                  }
                }
              }
            }
          }
        }
      }

      /* 
      row[seatIndex - 1].seat_reserve_type_id !== 8 &&
            row[seatIndex - 1].seat_reserve_type_id !== 2 &&
            row[seatIndex - 1].seat_reserve_type_id !== 13
      */

      return {
        ...state,
        seatLayout: newSeatLayout,
      };
    }
    case FETCH_SEAT_LAYOUT_ERROR:
      return { ...state, seatLayout: null, seatLayoutError: payload };
    case FETCH_SEAT_TYPE:
      return {
        ...state,
        seatType: null,
        seatTypeLoading: true,
      };
    case FETCH_SEAT_TYPE_SUCCESS:
      return {
        ...state,
        seatType: payload,
        seatTypeLoading: false,
      };
    case FETCH_SEAT_TYPE_ERROR:
      return { ...state, seatType: null, seatTypeError: payload };
    case SET_SEAT_SELECTED:
      const {
        SeatTypes,
        numberOfSeats,
        category_id,
        booking_type_id = null,
      } = payload;
      const newSeatLayout = [...state.seats_selected];
      const existingSeat = state.seats_selected.some(
        (seat) =>
          seat.sl_seat_name === SeatTypes.sl_seat_name &&
          seat.sl_row_num === SeatTypes.sl_row_num &&
          seat.sl_col_num === SeatTypes.sl_col_num &&
          seat.sl_id === SeatTypes.sl_id &&
          seat.seat_reserve_type_id === SeatTypes.seat_reserve_type_id &&
          seat.srt_id === SeatTypes.srt_id
      );
      console.log(
        "🚀 ~ file: movieReducer.js ~ line 756 ~ movieReducer ~ category_id",
        category_id
      );
      // let taxAmount =
      //   SeatTypes.seat_price - SeatTypes.seat_net_price.toFixed(2);
      if (existingSeat) {
        let finalIndex = state.seats_selected.findIndex(
          (seat) =>
            seat.sl_seat_name === SeatTypes.sl_seat_name &&
            seat.sl_row_num === SeatTypes.sl_row_num &&
            seat.sl_col_num === SeatTypes.sl_col_num &&
            seat.sl_id === SeatTypes.sl_id &&
            seat.seat_reserve_type_id === SeatTypes.seat_reserve_type_id &&
            seat.srt_id === SeatTypes.srt_id
        );
        SeatTypes.SeatSelected = !SeatTypes.SeatSelected;
        newSeatLayout.splice(finalIndex, 1);
        // state.selectedTicketPrice.priceInCents -= SeatTypes.seat_price;
        // state.selectedTicketPrice.taxInCents -= taxAmount;
      } else if (category_id == 2) {
        SeatTypes.SeatSelected = !SeatTypes.SeatSelected;
        newSeatLayout.push(SeatTypes);
        // state.selectedTicketPrice.priceInCents += SeatTypes.seat_price;
        // state.selectedTicketPrice.taxInCents += taxAmount;
      } else if (
        category_id == 1 &&
        (booking_type_id == 2 || booking_type_id == 1)
      ) {
        // its a crowd source screening
        SeatTypes.SeatSelected = !SeatTypes.SeatSelected;
        newSeatLayout.push(SeatTypes);
      }
      // else if (
      //   category_id != 2 &&
      //   numberOfSeats > state.seats_selected.length
      // ) {
      //   SeatTypes.SeatSelected = !SeatTypes.SeatSelected;
      //   newSeatLayout.push(SeatTypes);
      // }
      return {
        ...state,
        seats_selected: newSeatLayout,
      };

    case CLEAR_SEAT_SELECTION:
      state.seats_selected.map((s) => (s.SeatSelected = !s.SeatSelected));
      return {
        ...state,
        seats_selected: [],
      };
    case ON_SET_COMPLETE_RATE_CARD:
      return {
        ...state,
        isCompleteRateCard: payload.status,
        seatGroupAll: payload.seatGroup,
      };
    case ON_SET_COMPLETE_NEW_TOTAL_AMOUNT:
      return {
        ...state,
        selectedTicketPrice: {
          priceInCents: payload.totalAmount,
          taxInCents:
            payload.netAmount !== 0
              ? payload.totalAmount - payload.netAmount
              : state.selectedTicketPrice.taxInCents,
          totalWithFnb: state.selectedTicketPrice.totalWithFnb,
          taxWithFnb: state.selectedTicketPrice.taxWithFnb,
        },
        netAmount: payload.netAmount,
        totalAmount: payload.totalAmount,
      };
    case SET_STAGE:
      console.log("payload :>> ", payload);
      return {
        ...state,
        stage: payload.stage,
        // show: payload.show,
      };
    case SET_BOOKING_TYPE:
      return {
        ...state,
        booking_type: payload,
      };
    case SET_SHOW_BOOKING_ID:
      return {
        ...state,
        showBookingID: payload,
      };
    case FETCH_BOOKING_DETAILS:
      return {
        ...state,
        bookingDetails: null,
        bookingDetailsLoading: true,
      };
    case FETCH_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        bookingDetails: payload,
        bookingDetailsLoading: false,
      };
    case FETCH_BOOKING_DETAILS_ERROR:
      return { ...state, bookingDetails: null, bookingDetailsError: payload };
    case SET_STRIPE_PUBLISHER_KEY:
      return { ...state, stripePublisherKey: payload };
    case FETCH_MINI_FESTIVALS:
      return {
        ...state,
        miniFestivalsLoading: true,
      };
    case FETCH_MINI_FESTIVALS_SUCCESS:
      // console.log("payload ajit", payload);
      const { data, index } = payload;
      let festival;

      console.log("state.miniFestivals ====>", state.miniFestivals);

      if (state.miniFestivals === null || index === null) {
        festival = data;
        // if (state.selectedMiniFestival!==null) {
        //   const selectedFestivalIndex = state.miniFestivals.data.findIndex(
        //     (festival) =>
        //       festival.category_id === state.selectedMiniFestival.category_id &&
        //       festival.category_link_image ===
        //         state.selectedMiniFestival.category_link_image &&
        //       festival.category_link_movie_id ===
        //         state.selectedMiniFestival.category_link_movie_id &&
        //       festival.movie_id === state.selectedMiniFestival.movie_id &&
        //       festival.from_date === state.selectedMiniFestival.from_date &&
        //       festival.created_at === state.selectedMiniFestival.created_at
        //   );
        //   console.log(
        //     "🚀 ~ file: movieReducer.js ~ line 268 ~ movieReducer ~ selectedFestivalIndex",
        //     selectedFestivalIndex
        //   );
        // }
      } else {
        festival = state.miniFestivals;
        festival.Records[index].movie_data.data.push(
          ...data?.Records[0]?.movie_data.data
        );
        festival.Records[index].movie_data.pagination = {
          ...festival.Records[index].movie_data.pagination,
          ...data?.Records[0]?.movie_data.pagination,
        };
        festival.Records[index].currentPage =
          festival.Records[index].currentPage + 1;
      }
      return {
        ...state,
        miniFestivals: festival,
        miniFestivalsLoading: false,
      };
    case FETCH_MINI_FESTIVALS_ERROR:
      return {
        ...state,
        miniFestivals: null,
        miniFestivalsError: payload,
        miniFestivalsLoading: false,
      };
    case FETCH_OTHER_SCREENINGS:
      return {
        ...state,
        otherScreenings: [],
        otherScreeningsLoading: true,
      };
    case FETCH_OTHER_SCREENINGS_SUCCESS:
      return {
        ...state,
        otherScreenings: payload,
        otherScreeningsLoading: false,
      };
    case FETCH_OTHER_SCREENINGS_ERROR:
      return { ...state, otherScreenings: [], otherScreeningsError: payload };
    case FETCH_GLOBAL_OPTIONS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GLOBAL_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        globalOptions: payload,
        error: null,
      };
    case FETCH_GLOBAL_OPTIONS_ERROR:
      return {
        loading: false,
        globalOptions: [],
        error: payload,
      };
    case FETCH_CURRENCY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currency: payload,
        error: null,
      };
    case FETCH_CURRENCY_ERROR:
      return {
        loading: false,
        currency: {},
        error: payload,
      };
    case FETCH_SCREENING_TYPES:
      return {
        ...state,
        screeningTypes: [],
        screeningTypesLoading: true,
      };
    case FETCH_SCREENING_TYPES_SUCCESS:
      return {
        ...state,
        screeningTypes: payload,
        screeningTypesLoading: false,
      };
    case FETCH_SCREENING_TYPES_ERROR:
      return { ...state, screeningTypes: [], screeningTypesError: payload };
    case START_TIMER: {
      let oldTimer = state.timer;
      let newTimer = {};
      let newSeconds = oldTimer.seconds;
      let newMinutes = oldTimer.minutes;
      let timerCompleted = false;

      if (newSeconds === 0) {
        newSeconds = 59;
        if (newMinutes === 0) {
          // timer has ended
          timerCompleted = true;
          newSeconds = 0;
          newMinutes = 0;
          if (action.payload && action.payload.timerInterval) {
            clearInterval(action.payload.timerInterval);
            action.payload.timerInterval = null;
          }
        } else {
          newMinutes -= 1;
        }
      } else {
        newSeconds -= 1;
      }

      newTimer = {
        seconds: newSeconds,
        minutes: newMinutes,
      };

      return {
        ...state,
        timer: newTimer,
        timerCompleted,
      };
    }
    case STOP_TIMER:
      if (action.payload && action.payload.timerInterval) {
        clearInterval(action.payload.timerInterval);
      }
      return { ...state };
    // return{...state,
    // }
    case RESET_TIMER: {
      return {
        ...state,
        timer: {
          seconds: INITIAL_SECONDS,
          minutes: INITIAL_MINS,
        },
        timerCompleted: false,
      };
    }
    case SET_TIMER: {
      return { ...state, timer: payload };
    }

    case SET_FROM_MOVIE_PAGE: {
      return {
        ...state,
        fromMoviePage: true,
      };
    }
    case SET_IS_CREATE_YOUR_SHOW_ENABLED: {
      return {
        ...state,
        createYourShowEnabled: payload,
      };
    }
    case SET_MOVIE_FORMATS: {
      return {
        ...state,
        movieFormats: payload,
      };
    }
    case SET_MOVIE_LANGUAGES: {
      return {
        ...state,
        movieLanguages: payload,
      };
    }
    case SET_TEMP_RESERVATION_ID: {
      return {
        ...state,
        reservation_id_temp: payload,
      };
    }
    case FETCH_ALL_MOVIES_SCHEDULES: {
      return {
        ...state,
        allMoviesSchedulesLoading: true,
        allMoviesSchedulesError: null,
      };
    }
    case FETCH_ALL_MOVIES_SCHEDULES_SUCCESS: {
      return {
        ...state,
        allMoviesSchedulesLoading: false,
        allMoviesSchedulesError: null,
        allMoviesSchedules: action.payload,
      };
    }
    case FETCH_ALL_MOVIES_SCHEDULES_ERROR: {
      return {
        ...state,
        allMoviesSchedulesLoading: false,
        allMoviesSchedulesError: action.payload,
        allMoviesSchedules: {
          movieSchedules: [],
          datesArray: [],
        },
      };
    }
    default:
      return state;
  }
};

export default movieReducer;
