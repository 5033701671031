import AuthService from "@apiService/AuthService";
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  FETCH_USER_HISTORY,
  FETCH_USER_HISTORY_SUCCESS,
  FETCH_USER_HISTORY_ERROR,
  LOGOUT,
  UPDATE_USER_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  OTP_SUCCESS,
  OTP_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS_ERROR,
  LOGIN_VIA_OTP_SUCCESS,
  LOGIN_VIA_OTP_ERROR,
  START_OTP_TIMER,
  STOP_OTP_TIMER,
  RESET_OTP_TIMER,
  CONTACTUSMAIL,
  SHOW_LOGIN_POPUP,
} from "./authTypes";
import { toast } from "react-toastify";
const _ = require("lodash");

export const login = ({ loginField, password, callback }) => async (
  dispatch
) => {
  try {
    dispatch({ type: LOGIN });
    const payload = { password };
    console.log(emailRegex(loginField));
    if (emailRegex(loginField)) {
      payload.email = loginField;
    } else {
      payload.mobileNo = loginField;
    }
    const { data } = await AuthService.Login(payload);
    if (data.status && data.Records) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data.Records[0],
      });
      localStorage.setItem("user", JSON.stringify(data.Records[0]));
      // toast.dismiss();
      // toast.success(`Login successful.`);
    } else {
      dispatch({ type: LOGIN_ERROR, payload: data.message });
      // toast.dismiss();
      // toast.error(data.message);
    }
    callback(false);
  } catch (error) {
    dispatch({ type: LOGIN_ERROR, payload: error });
  }
};

export const register = ({
  email,
  first_name,
  last_name,
  password,
  phone_no,
  country_id,
  callback,
}) => async (dispatch) => {
  console.log("country_id", country_id);
  try {
    dispatch({ type: REGISTER });
    const { data } = await AuthService.Register({
      email,
      first_name,
      last_name,
      password,
      phone_no,
      country_id,
    });
    const payload = {
      cust_email: email,
      first_name,
      last_name,
      password,
      cust_mobile: phone_no,
      country_id,
    };
    if (data.status) {
      dispatch({
        type: REGISTER_SUCCESS,
        payload,
      });
      payload.cust_id = data.cust_id;
      localStorage.setItem("user", JSON.stringify(payload));
      // toast.dismiss();
      // toast.success(`Registration successful.`);
    } else {
      dispatch({ type: REGISTER_ERROR, payload: data.message });
      // toast.dismiss();
      // toast.error(data.message);
    }
    callback(false);
  } catch (error) {
    dispatch({ type: REGISTER_ERROR, payload: error });
  }
};

export const fetchUserHistory = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USER_HISTORY });
    const { data } = await AuthService.GetUserHistory(payload);
    if (data.status && data.Records.length > 0) {
      let result = _.chain(data?.Records)
        .groupBy("show_booking_id")
        .toPairs()
        .map((currentItem) =>
          _.fromPairs(_.zip(["show_booking_id", "Seats"], currentItem))
        )
        .value();
      result = result.sort((a, b) => b.show_booking_id - a.show_booking_id);

      result.forEach((history) => {
        history.seatsString = [];
        let seat_names_set = new Set(
          history?.Seats?.[0].seat_names?.split(",")
        );
        history.seatsString = [...seat_names_set];
        // history.Seats.map((seat) => history.seatsString.push(seat.seat_name));
        history.seatsString.sort(sortSeatsString);
      });

      console.log("result", result);

      dispatch({
        type: FETCH_USER_HISTORY_SUCCESS,
        payload: result,
      });
    } else {
      dispatch({ type: FETCH_USER_HISTORY_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_USER_HISTORY_ERROR, payload: error });
  }
};

export const sortSeatsString = (a, b) => {
  let reA = /[^a-zA-Z]/g;
  let reN = /[^0-9]/g;
  let aA = a.replace(reA, "");
  let bA = b.replace(reA, "");
  if (aA === bA) {
    let aN = parseInt(a.replace(reN, ""), 10);
    let bN = parseInt(b.replace(reN, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
};

export const logout = ({ history }) => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  localStorage.removeItem("user");
  // toast.dismiss();
  // toast.success(`Logout successful`);
  history.push(`/`);
};

export const updateUser = ({
  first_name,
  last_name,
  email,
  phone_no,
  callback,
}) => async (dispatch) => {
  try {
    const { data } = await AuthService.UpdateUser({
      first_name,
      last_name,
      email,
      phone_no,
    });
    if (data && data.status) {
      dispatch({ type: UPDATE_USER_SUCCESS, payload: data.Records[0] });
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(data.Records[0]));
      callback(false);
      // toast.dismiss();
      // toast.success(`User updated successfully`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const forgotPassword = ({ field, callback, successCallback }) => async (
  dispatch
) => {
  try {
    const payload = {};
    console.log(emailRegex(field));
    if (emailRegex(field)) {
      payload.email = field;
    } else {
      payload.mobile = field;
    }
    const { data } = await AuthService.ForgotPassword(payload);
    if (data && data.status) {
      dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.Records[0] });
      successCallback();
    } else {
      dispatch({ type: FORGOT_PASSWORD_ERROR, payload: data.message });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: FORGOT_PASSWORD_ERROR });
  } finally {
    callback(false);
  }
};

export const verifyOTP = ({
  otp,
  callback,
  successCallback,
  forLogin,
}) => async (dispatch, getState) => {
  try {
    const {
      auth: {
        forgotPasswordUserDetails: { cust_id },
        loginOtpSuccess,
      },
    } = getState();

    const { data } = await AuthService.VerifyOTP({ cust_id, otp });
    if (data && data.status) {
      dispatch({ type: OTP_SUCCESS, payload: { otp } });
      successCallback();
    } else {
      dispatch({ type: OTP_ERROR, payload: data.message });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: OTP_ERROR });
  } finally {
    callback(false);
  }
};

export const verifyLoginOTP = ({ otp, callback, successCallback }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      auth: {
        loginOtpSuccess: { cust_id },
      },
    } = getState();

    const { data } = await AuthService.VerifyOTP({ cust_id, otp });
    if (data && data.status) {
      dispatch({ type: OTP_SUCCESS, payload: { otp } });

      dispatch({
        type: LOGIN_SUCCESS,
        payload: data.user,
      });
      localStorage.setItem("user", JSON.stringify(data.user));
      successCallback();
    } else {
      dispatch({ type: OTP_ERROR, payload: data.message });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: OTP_ERROR });
  } finally {
    callback(false);
  }
};

export const resetPassword = ({
  newPassword,
  callback,
  successCallback,
}) => async (dispatch, getState) => {
  try {
    const {
      auth: {
        forgotPasswordUserDetails: { cust_id, cust_email, first_name },
        otpSuccess: { otp },
      },
    } = getState();
    const { data } = await AuthService.ResetPassword({
      password: newPassword,
      otp,
      cust_id,
      cust_email,
      first_name,
    });
    if (data && data.status) {
      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: {} });
      successCallback();
    } else {
      dispatch({ type: RESET_PASSWORD_SUCCESS_ERROR, payload: data.message });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: RESET_PASSWORD_SUCCESS_ERROR });
  } finally {
    callback(false);
  }
};

export const emailRegex = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const numberRegex = (number) => {
  const re = /^\d+$/;
  return re.test(String(number).toLowerCase());
};

export const loginViaOtp = ({
  loginField,
  callback,
  successCallback,
}) => async (dispatch, getState) => {
  try {
    const payload = {};
    console.log(emailRegex(loginField));
    if (emailRegex(loginField)) {
      payload.email = loginField;
    } else {
      payload.mobile = loginField;
    }
    const { data } = await AuthService.LoginViaOtp(payload);
    if (data && data.status) {
      dispatch({ type: LOGIN_VIA_OTP_SUCCESS, payload: data.Records[0] });
      successCallback();
      return data;
    } else {
      dispatch({ type: LOGIN_VIA_OTP_ERROR, payload: data.message });
      return data;
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LOGIN_VIA_OTP_ERROR });
  } finally {
    callback(false);
  }
};

let timerInterval = null;
export const startTimer = () => (dispatch) => {
  timerInterval = setInterval(
    () =>
      dispatch({
        type: START_OTP_TIMER,
        payload: {
          timerInterval,
        },
      }),
    1 * 1000
  );
};

export const stopTimer = () => (dispatch) => {
  if (timerInterval) {
    clearInterval(timerInterval);
  }
  dispatch({
    type: STOP_OTP_TIMER,
  });
};

export const resetTimer = () => (dispatch) => {
  if (timerInterval) {
    clearInterval(timerInterval);
  }
  dispatch({ type: RESET_OTP_TIMER });
};

//Contact Us Form --> Send Email
export const sendemail = ({ name, email, contact_no, message }) => async (
  dispatch
) => {
  try {
    const { data } = await AuthService.ContactusMail({
      name,
      email,
      contact_no,
      message,
    });
    if ((data, data.status)) {
      console.log(data);
      dispatch({ type: CONTACTUSMAIL, payload: data });
    }
  } catch (err) {
    console.log(err);
  }
};

export const setShowLoginPopup = (toShow) => ({
  type: SHOW_LOGIN_POPUP,
  payload: toShow,
});
